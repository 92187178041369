
import React from "react";
import { FeedType, feedTypeConfigs } from "@/lib/types";
import { cn } from "@/lib/utils";
import { 
  Thermometer, 
  Droplets, 
  Ruler, 
  Flame,
  Waves,
  FlaskConical,
  LucideIcon 
} from "lucide-react";

interface TemperatureCircleProps {
  title: string;
  value?: number;
  useFahrenheit: boolean;
  feedType: FeedType;
  size?: "small" | "medium" | "large";
}

// Map of feed types to their respective icons
const iconMap: Record<FeedType, LucideIcon> = {
  [FeedType.Temperature]: Thermometer,
  [FeedType.Humidity]: Droplets,
  [FeedType.Distance]: Ruler,
  [FeedType.Thermocouple]: Flame,
  [FeedType.TDS1]: Waves,
  [FeedType.TDS2]: Waves,
  [FeedType.PH]: FlaskConical
};

export function TemperatureCircle({ title, value, useFahrenheit, feedType, size = "large" }: TemperatureCircleProps) {
  const config = feedTypeConfigs[feedType];
  
  // Handle display value and unit based on feed type
  let displayValue = value;
  let displayUnit = "";
  
  if (displayValue !== undefined) {
    if (feedType === FeedType.Temperature || feedType === FeedType.Thermocouple) {
      // Only convert temperature values between F and C
      displayValue = useFahrenheit ? value : ((value! - 32) * 5) / 9;
      displayUnit = useFahrenheit ? "°F" : "°C";
    } else if (feedType === FeedType.Humidity) {
      // For humidity, don't convert, just use the value directly
      displayUnit = "%";
    } else if (feedType === FeedType.TDS1 || feedType === FeedType.TDS2) {
      displayUnit = "ppm";
    } else if (feedType === FeedType.PH) {
      displayUnit = "pH";
    } else if (feedType === FeedType.Distance) {
      displayUnit = "cm";
    }
  }
  
  // Get the icon component using the map
  const IconComponent = iconMap[feedType];
  
  // Size classes
  const circleSize = {
    small: "w-24 h-24",
    medium: "w-32 h-32 sm:w-36 sm:h-36",
    large: "w-40 h-40 sm:w-48 sm:h-48"
  }[size];
  
  const innerCircleSize = {
    small: "w-20 h-20",
    medium: "w-24 h-24 sm:w-28 sm:h-28",
    large: "w-32 h-32 sm:w-36 sm:h-36"
  }[size];
  
  const valueTextSize = {
    small: "text-xl sm:text-2xl",
    medium: "text-2xl sm:text-3xl",
    large: "text-3xl sm:text-4xl"
  }[size];
  
  const unitTextSize = {
    small: "text-sm",
    medium: "text-base sm:text-lg",
    large: "text-lg sm:text-xl"
  }[size];
  
  const titleTextSize = {
    small: "text-sm",
    medium: "text-md",
    large: "text-lg"
  }[size];
  
  const iconSize = {
    small: "h-4 w-4",
    medium: "h-5 w-5",
    large: "h-5 w-5"
  }[size];
  
  return (
    <div className="flex flex-col items-center py-2">
      <h3 className={`${titleTextSize} font-medium text-slate-600 dark:text-slate-300 mb-2 flex items-center gap-2`}>
        <IconComponent className={iconSize} style={{ color: config.color }} />
        {title}
      </h3>
      
      <div className="relative">
        <div className={`relative ${circleSize} flex items-center justify-center overflow-hidden`}>
          {/* Background gradient circle with pulsing animation */}
          <div 
            className="absolute inset-0 rounded-full opacity-20 animate-pulse"
            style={{
              background: `radial-gradient(circle, ${config.gradientStart} 0%, ${config.gradientEnd} 70%)`
            }}
          ></div>
          
          {/* Outer ring with glow effect */}
          <div 
            className="absolute inset-0 rounded-full border-4 opacity-70"
            style={{ 
              borderColor: config.gradientStart,
              boxShadow: `0 0 15px rgba(${parseInt(config.color.slice(1, 3), 16)}, ${parseInt(config.color.slice(3, 5), 16)}, ${parseInt(config.color.slice(5, 7), 16)}, 0.3)`
            }}
          ></div>
          
          {/* Inner styling */}
          <div 
            className={cn(
              `${innerCircleSize} rounded-full flex flex-col items-center justify-center shadow-lg`,
              "bg-gradient-to-br from-white/80 to-white/30 dark:from-slate-800/80 dark:to-slate-900/30",
              "backdrop-blur-sm border border-white/40 dark:border-white/5"
            )}
          >
            {displayValue !== undefined ? (
              <>
                <div 
                  className={`${valueTextSize} font-bold`}
                  style={{ color: config.color }}
                >
                  {displayValue.toFixed(1)}
                </div>
                <div className={`${unitTextSize} text-slate-500 dark:text-slate-400`}>
                  {displayUnit}
                </div>
              </>
            ) : (
              <div className="text-4xl font-bold text-slate-400">--</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
