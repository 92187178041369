
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { toast } from "@/hooks/use-toast";

interface PasswordDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess: () => void;
}

export function PasswordDialog({ open, onOpenChange, onSuccess }: PasswordDialogProps) {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  // The development password
  const DEV_PASSWORD = "sakana";

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (password === DEV_PASSWORD) {
      setError(false);
      setPassword("");
      onSuccess();
      toast({
        title: "Access granted",
        description: "Welcome to the Avenix dashboard",
      });
    } else {
      setError(true);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Development Access</DialogTitle>
          <DialogDescription>
            Enter the development password to access the dashboard.
          </DialogDescription>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4 py-4">
          <Input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              if (error) setError(false);
            }}
            className="w-full"
            autoFocus
          />

          {error && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                Incorrect password. Please try again.
              </AlertDescription>
            </Alert>
          )}

          <DialogFooter className="pt-4">
            <Button variant="outline" type="button" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button type="submit">Continue</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
