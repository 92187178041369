
import { ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { 
  Gauge, 
  Thermometer, 
  Settings, 
  Menu, 
  X,
  Waves,
  Home,
  BookOpen,
  Droplets
} from "lucide-react";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { useAdafruit } from "@/lib/AdafruitContext";

interface LayoutProps {
  children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const { refreshInterval, autoRefresh } = useAdafruit();
  
  return (
    <div className="min-h-screen bg-slate-50 dark:bg-slate-900 flex flex-col">
      {/* Top header for mobile */}
      <header className="lg:hidden bg-white dark:bg-slate-800 border-b border-slate-200 dark:border-slate-700 p-4 flex items-center justify-between">
        <h1 className="text-xl font-bold bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">
          Avenix
        </h1>
        <button 
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="rounded-full p-2 hover:bg-slate-100 dark:hover:bg-slate-700 transition-colors"
        >
          {isSidebarOpen ? <X size={20} /> : <Menu size={20} />}
        </button>
      </header>
      
      {/* Sidebar */}
      <div className={cn(
        "fixed inset-y-0 left-0 z-50 w-64 bg-white dark:bg-slate-800 border-r border-slate-200 dark:border-slate-700 transform transition-transform duration-300 ease-in-out lg:translate-x-0",
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      )}>
        <div className="p-6">
          <h1 className="text-2xl font-bold bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">
            Avenix
          </h1>
        </div>
        
        <nav className="px-3 py-6">
          <ul className="space-y-1">
            <NavItem to="/" icon={<Home />} label="Welcome" active={location.pathname === "/"} />
            <NavItem to="/dashboard" icon={<Gauge />} label="Dashboard" active={location.pathname === "/dashboard"} />
            <NavItem to="/temperature" icon={<Thermometer />} label="Temperature" active={location.pathname === "/temperature"} />
            <NavItem to="/humidity" icon={<Droplets />} label="Humidity" active={location.pathname === "/humidity"} />
            <NavItem to="/water-quality" icon={<Waves />} label="Water Quality" active={location.pathname === "/water-quality"} />
            <NavItem to="/knowledge" icon={<BookOpen />} label="Knowledge Base" active={location.pathname === "/knowledge"} />
            <NavItem to="/settings" icon={<Settings />} label="Settings" active={location.pathname === "/settings"} />
          </ul>
        </nav>
        
        <div className="absolute bottom-0 left-0 right-0 p-4">
          <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-lg p-4 text-xs text-center text-slate-600 dark:text-slate-400">
            <p>
              {autoRefresh 
                ? `Sensor data updates every ${refreshInterval} seconds` 
                : "Auto-refresh is disabled"}
            </p>
          </div>
        </div>
      </div>
      
      {/* Overlay */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black/20 z-40 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}
      
      {/* Main content */}
      <main className="flex-1 lg:ml-64 p-4 md:p-8">
        {children}
      </main>
    </div>
  );
}

interface NavItemProps {
  to: string;
  icon: ReactNode;
  label: string;
  active: boolean;
}

function NavItem({ to, icon, label, active }: NavItemProps) {
  return (
    <li>
      <NavLink
        to={to}
        className={cn(
          "flex items-center gap-3 px-4 py-3 rounded-lg transition-colors",
          active 
            ? "bg-gradient-to-r from-blue-500 to-purple-600 text-white" 
            : "text-slate-600 dark:text-slate-400 hover:bg-slate-100 dark:hover:bg-slate-700"
        )}
        end={true}
      >
        {icon}
        <span className="font-medium">{label}</span>
      </NavLink>
    </li>
  );
}
