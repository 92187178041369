
import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: "https://151e9c4926c7068ead528ca114342b40@o4509023033491456.ingest.us.sentry.io/4509025118191616",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  tracesSampleRate: 1.0,
});

createRoot(document.getElementById("root")!).render(<App />);
