
export function formatDate(dateInput: string | Date): string {
  try {
    const date = dateInput instanceof Date ? dateInput : new Date(dateInput);
    
    // Check if date is valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date:", dateInput);
      return "Invalid date";
    }
    
    const options: Intl.DateTimeFormatOptions = { 
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    
    return new Intl.DateTimeFormat('en-US', options).format(date);
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Date error";
  }
}

export function formatTime(dateInput: string | Date): string {
  try {
    const date = dateInput instanceof Date ? dateInput : new Date(dateInput);
    
    // Check if date is valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date for time formatting:", dateInput);
      return "Invalid time";
    }
    
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    };
    
    return new Intl.DateTimeFormat('en-US', options).format(date);
  } catch (error) {
    console.error("Error formatting time:", error);
    return "Time error";
  }
}

export function formatChartDate(dateInput: string | Date): string {
  try {
    const date = dateInput instanceof Date ? dateInput : new Date(dateInput);
    
    // Check if date is valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date for chart:", dateInput);
      return "";
    }
    
    // Format day and month
    const monthDay = new Intl.DateTimeFormat('en-US', { 
      month: 'short', 
      day: 'numeric'
    }).format(date);
    
    // Format time
    const time = new Intl.DateTimeFormat('en-US', { 
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(date);
    
    // Combine as "MMM DD, h:mm a"
    return monthDay;
  } catch (error) {
    console.error("Error formatting chart date:", error);
    return "";
  }
}

export function formatAxisTime(dateInput: string | Date | number): string {
  try {
    const date = typeof dateInput === 'number' 
      ? new Date(dateInput) 
      : (dateInput instanceof Date ? dateInput : new Date(dateInput));
    
    // Check if date is valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date for axis time:", dateInput);
      return "";
    }
    
    // Format as just the hour "h AM/PM"
    return new Intl.DateTimeFormat('en-US', { 
      hour: 'numeric',
      hour12: true
    }).format(date);
  } catch (error) {
    console.error("Error formatting axis time:", error);
    return "";
  }
}

// Helper function to get hour intervals for the chart as timestamps
export function generateHourIntervals(startTime: Date, count: number = 6): number[] {
  const intervals: number[] = [];
  const currentTime = new Date(startTime);
  
  for (let i = 0; i < count; i++) {
    intervals.push(currentTime.getTime());
    currentTime.setHours(currentTime.getHours() - 1);
  }
  
  return intervals.reverse();
}
