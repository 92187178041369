
import { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { AdafruitFeedData, SensorData, FeedType } from "./types";
import { useToast } from "@/hooks/use-toast";

interface AdafruitContextType {
  distanceData: AdafruitFeedData[];
  humidityData: AdafruitFeedData[];
  temperatureData: AdafruitFeedData[];
  thermocoupleData: AdafruitFeedData[];
  tds1Data: AdafruitFeedData[];
  tds2Data: AdafruitFeedData[];
  phData: AdafruitFeedData[];
  sensorData: SensorData;
  lastUpdated: Date | null;
  isLoading: boolean;
  error: string | null;
  useFahrenheit: boolean;
  setUseFahrenheit: (value: boolean) => void;
  useMetric: boolean;
  setUseMetric: (value: boolean) => void;
  autoRefresh: boolean;
  setAutoRefresh: (value: boolean) => void;
  refreshInterval: number;
  setRefreshInterval: (value: number) => void;
  fetchSensorData: () => void;
  getUnit: (feed: FeedType) => string;
  retryFetch: () => void;
}

const AdafruitContext = createContext<AdafruitContextType | undefined>(undefined);

export function useAdafruit() {
  const context = useContext(AdafruitContext);
  if (context === undefined) {
    throw new Error("useAdafruit must be used within an AdafruitProvider");
  }
  return context;
}

interface AdafruitProviderProps {
  children: ReactNode;
}

export function AdafruitProvider({ children }: AdafruitProviderProps) {
  // Adafruit IO Credentials - matching those in the ESP32 code
  const username = "cnfishburn";
  const aioKey = "aio_YeIO11c7du21PWq3XgfBDaSNwEa1";

  // State for feed data
  const [distanceData, setDistanceData] = useState<AdafruitFeedData[]>([]);
  const [humidityData, setHumidityData] = useState<AdafruitFeedData[]>([]);
  const [temperatureData, setTemperatureData] = useState<AdafruitFeedData[]>([]);
  const [thermocoupleData, setThermocoupleData] = useState<AdafruitFeedData[]>([]);
  const [tds1Data, setTds1Data] = useState<AdafruitFeedData[]>([]);
  const [tds2Data, setTds2Data] = useState<AdafruitFeedData[]>([]);
  const [phData, setPhData] = useState<AdafruitFeedData[]>([]);
  
  // State for sensor data
  const [sensorData, setSensorData] = useState<SensorData>({});
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  
  // State for loading and error handling
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [maxRetries] = useState(3);
  
  // User settings - using localStorage for persistence
  const [serverAddress, setServerAddress] = useState(() => 
    localStorage.getItem('serverAddress') || '192.168.1.75'
  );
  const [useFahrenheit, setUseFahrenheit] = useState(() => 
    localStorage.getItem('useFahrenheit') !== 'false'
  );
  const [useMetric, setUseMetric] = useState(() => 
    localStorage.getItem('useMetric') === 'true'
  );
  // Changed the default value for autoRefresh to false
  const [autoRefresh, setAutoRefresh] = useState(() => 
    localStorage.getItem('autoRefresh') === 'true'
  );
  const [refreshInterval, setRefreshInterval] = useState(() => 
    Number(localStorage.getItem('refreshInterval')) || 5
  );
  
  // Timer for auto-refresh
  const [refreshTimer, setRefreshTimer] = useState<NodeJS.Timeout | null>(null);
  
  const { toast } = useToast();

  // Persist settings to localStorage
  useEffect(() => {
    localStorage.setItem('serverAddress', serverAddress);
  }, [serverAddress]);
  
  useEffect(() => {
    localStorage.setItem('useFahrenheit', String(useFahrenheit));
  }, [useFahrenheit]);
  
  useEffect(() => {
    localStorage.setItem('useMetric', String(useMetric));
  }, [useMetric]);
  
  useEffect(() => {
    localStorage.setItem('autoRefresh', String(autoRefresh));
  }, [autoRefresh]);
  
  useEffect(() => {
    localStorage.setItem('refreshInterval', String(refreshInterval));
  }, [refreshInterval]);

  // Setup auto-refresh
  useEffect(() => {
    if (autoRefresh) {
      startAutoRefresh();
    } else {
      stopAutoRefresh();
    }
    
    return () => stopAutoRefresh();
  }, [autoRefresh, refreshInterval]);

  // Fetch all feeds when component mounts
  useEffect(() => {
    fetchSensorData();
  }, []);

  const startAutoRefresh = () => {
    stopAutoRefresh();
    
    if (!autoRefresh) return;
    
    const timer = setInterval(() => {
      fetchSensorData();
    }, refreshInterval * 1000);
    
    setRefreshTimer(timer);
  };

  const stopAutoRefresh = () => {
    if (refreshTimer) {
      clearInterval(refreshTimer);
      setRefreshTimer(null);
    }
  };

  const fetchFeedData = async (feedName: string, currentRetry = 0): Promise<AdafruitFeedData[]> => {
    // Using the Adafruit IO API endpoint structure
    const urlString = `https://io.adafruit.com/api/v2/${username}/feeds/${feedName}/data?limit=20`;
    
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 15000); // 15 second timeout
      
      const response = await fetch(urlString, {
        headers: {
          'X-AIO-Key': aioKey
        },
        signal: controller.signal
      });
      
      clearTimeout(timeoutId);
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Server returned ${response.status} ${response.statusText}: ${errorText}`);
      }
      
      const feedItems: AdafruitFeedData[] = await response.json();
      const sortedItems = feedItems.sort((a, b) => 
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      
      // Assign results to the correct state
      switch (feedName.toLowerCase()) {
        case "distance":
          setDistanceData(sortedItems);
          if (sortedItems.length > 0) {
            setSensorData(prev => ({
              ...prev,
              distance: parseFloat(sortedItems[0].value)
            }));
          }
          break;
        case "humidity":
          setHumidityData(sortedItems);
          if (sortedItems.length > 0) {
            setSensorData(prev => ({
              ...prev,
              humidity: parseFloat(sortedItems[0].value)
            }));
          }
          break;
        case "temperature":
          setTemperatureData(sortedItems);
          if (sortedItems.length > 0) {
            setSensorData(prev => ({
              ...prev,
              temperatureF: parseFloat(sortedItems[0].value)
            }));
          }
          break;
        case "thermocouple":
          setThermocoupleData(sortedItems);
          if (sortedItems.length > 0) {
            setSensorData(prev => ({
              ...prev,
              thermocoupleF: parseFloat(sortedItems[0].value)
            }));
          }
          break;
        case "tds1":
          setTds1Data(sortedItems);
          if (sortedItems.length > 0) {
            setSensorData(prev => ({
              ...prev,
              tds1: parseFloat(sortedItems[0].value)
            }));
          }
          break;
        case "tds2":
          setTds2Data(sortedItems);
          if (sortedItems.length > 0) {
            setSensorData(prev => ({
              ...prev,
              tds2: parseFloat(sortedItems[0].value)
            }));
          }
          break;
        case "ph":
          setPhData(sortedItems);
          if (sortedItems.length > 0) {
            setSensorData(prev => ({
              ...prev,
              ph: parseFloat(sortedItems[0].value)
            }));
          }
          break;
        default:
          console.log(`Received data for unknown feed: ${feedName}`);
      }
      
      setLastUpdated(new Date());
      return sortedItems;
      
    } catch (error) {
      console.error(`Error fetching ${feedName}:`, error);
      const errorMessage = error instanceof Error ? error.message : String(error);
      
      // Only show toast for the first retry
      if (currentRetry === 0) {
        toast({
          title: "Connection Error",
          description: `Failed to fetch ${feedName} data: ${errorMessage.includes('abort') ? 'Request timed out' : errorMessage}`,
          variant: "destructive"
        });
      }
      
      // For network errors or timeouts, try to retry
      if (currentRetry < maxRetries && 
         (errorMessage.includes('NetworkError') || 
          errorMessage.includes('abort') || 
          errorMessage.includes('Failed to fetch'))) {
        console.log(`Retrying ${feedName} (${currentRetry + 1}/${maxRetries})...`);
        // Exponential backoff
        const delay = Math.min(1000 * Math.pow(2, currentRetry), 8000);
        await new Promise(resolve => setTimeout(resolve, delay));
        return fetchFeedData(feedName, currentRetry + 1);
      }
      
      // If we've exhausted retries or it's not a retryable error
      setError(`Error fetching ${feedName}: ${errorMessage}`);
      throw error;
    }
  };

  const fetchSensorData = async () => {
    setIsLoading(true);
    setError(null);
    setRetryCount(0);
    
    try {
      // Fetch all feeds - matches the feeds from your ESP32 code (tds1, tds2, ph)
      // plus the other sensors already in your application
      await Promise.allSettled([
        fetchFeedData("distance"),
        fetchFeedData("humidity"),
        fetchFeedData("temperature"),
        fetchFeedData("thermocouple"),
        fetchFeedData("tds1"),
        fetchFeedData("tds2"),
        fetchFeedData("ph")
      ]);
      
      // Check if any promises were rejected
      setLastUpdated(new Date());
    } catch (error) {
      console.error("Error fetching sensor data:", error);
      // We don't set the overall error here since individual feed errors are handled in fetchFeedData
    } finally {
      setIsLoading(false);
    }
  };

  const retryFetch = () => {
    setRetryCount(prev => prev + 1);
    if (retryCount < maxRetries) {
      toast({
        title: "Retrying connection",
        description: `Attempt ${retryCount + 1} of ${maxRetries}`,
      });
      fetchSensorData();
    } else {
      toast({
        title: "Connection failed",
        description: "Max retry attempts reached. Please check your connection and try again later.",
        variant: "destructive"
      });
    }
  };

  const getUnit = (feed: FeedType): string => {
    switch (feed) {
      case FeedType.Distance:
        return useMetric ? "cm" : "in";
      case FeedType.Humidity:
        return "%";
      case FeedType.Temperature:
      case FeedType.Thermocouple:
        return useFahrenheit ? "°F" : "°C";
      case FeedType.TDS1:
      case FeedType.TDS2:
        return "ppm";
      case FeedType.PH:
        return "pH";
      default:
        return "";
    }
  };

  const value = {
    distanceData,
    humidityData,
    temperatureData,
    thermocoupleData,
    tds1Data,
    tds2Data,
    phData,
    sensorData,
    lastUpdated,
    isLoading,
    error,
    useFahrenheit,
    setUseFahrenheit,
    useMetric,
    setUseMetric,
    autoRefresh,
    setAutoRefresh,
    refreshInterval,
    setRefreshInterval,
    fetchSensorData,
    retryFetch,
    getUnit
  };

  return (
    <AdafruitContext.Provider value={value}>
      {children}
    </AdafruitContext.Provider>
  );
}
