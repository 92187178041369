
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { AdafruitProvider } from "@/lib/AdafruitContext";
import { Layout } from "@/components/Layout";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import WelcomePage from "./pages/WelcomePage";
import DashboardPage from "./pages/DashboardPage";
import TemperaturePage from "./pages/TemperaturePage";
import WaterQualityPage from "./pages/WaterQualityPage";
import HumidityPage from "./pages/HumidityPage";
import SettingsPage from "./pages/SettingsPage";
import FeedDetailPage from "./pages/FeedDetailPage";
import KnowledgePage from "./pages/KnowledgePage";
import NotFound from "./pages/NotFound";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 30000,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <TooltipProvider>
      <AdafruitProvider>
        <HashRouter>
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<WelcomePage />} />
              
              {/* App routes with Layout */}
              <Route path="/dashboard" element={
                <Layout>
                  <DashboardPage />
                </Layout>
              } />
              <Route path="/temperature" element={
                <Layout>
                  <ErrorBoundary>
                    <TemperaturePage />
                  </ErrorBoundary>
                </Layout>
              } />
              <Route path="/water-quality" element={
                <Layout>
                  <ErrorBoundary>
                    <WaterQualityPage />
                  </ErrorBoundary>
                </Layout>
              } />
              <Route path="/humidity" element={
                <Layout>
                  <ErrorBoundary>
                    <HumidityPage />
                  </ErrorBoundary>
                </Layout>
              } />
              <Route path="/knowledge" element={
                <Layout>
                  <ErrorBoundary>
                    <KnowledgePage />
                  </ErrorBoundary>
                </Layout>
              } />
              <Route path="/feed/:feedType" element={
                <Layout>
                  <FeedDetailPage />
                </Layout>
              } />
              <Route path="/settings" element={
                <Layout>
                  <SettingsPage />
                </Layout>
              } />
              
              {/* Redirect old root to dashboard for backward compatibility */}
              <Route path="/dashboard/old" element={<Navigate to="/dashboard" replace />} />
              
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ErrorBoundary>
        </HashRouter>
        <Toaster />
        <Sonner />
      </AdafruitProvider>
    </TooltipProvider>
  </QueryClientProvider>
);

export default App;
