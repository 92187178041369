
import { useState } from "react";
import { useWeather } from "@/lib/services/WeatherService";
import { useAdafruit } from "@/lib/AdafruitContext";
import { ThermometerSun, MapPin, RefreshCcw, Cloud } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";

export const LocationTemperatureWidget = () => {
  const { weatherData, isLoading, error, fetchUserLocation } = useWeather();
  const { useFahrenheit } = useAdafruit();

  // Convert temperature if needed
  const temperatureValue = weatherData?.temperature !== undefined ? 
    (useFahrenheit ? weatherData.temperature : ((weatherData.temperature - 32) * 5) / 9) : 
    undefined;

  if (isLoading) {
    return (
      <div className="bg-white dark:bg-slate-800 rounded-full shadow border border-slate-200 dark:border-slate-700 py-2 px-4 flex items-center gap-2 h-[38px]">
        <ThermometerSun size={18} className="text-amber-500" />
        <Skeleton className="h-4 w-16" />
      </div>
    );
  }

  if (!weatherData && !isLoading) {
    return (
      <Button 
        variant="outline" 
        size="sm" 
        className="rounded-full"
        onClick={fetchUserLocation}
      >
        <RefreshCcw size={14} className="mr-1" />
        Get local weather
      </Button>
    );
  }

  return (
    <div className="bg-white dark:bg-slate-800 rounded-full shadow border border-slate-200 dark:border-slate-700 py-3 px-5 flex items-center gap-3 min-w-fit">
      <ThermometerSun size={20} className="text-amber-500 flex-shrink-0" />
      <div className="flex items-center whitespace-nowrap pl-3 pr-1">
        <span className="font-medium text-lg min-w-[3rem]">{temperatureValue !== undefined ? temperatureValue.toFixed(1) : "--"}</span>
        <span className="text-sm text-slate-500 ml-1">{useFahrenheit ? '°F' : '°C'}</span>
      </div>
      {weatherData?.location && (
        <div className="flex items-center gap-1 text-sm text-slate-500 whitespace-nowrap">
          <MapPin size={14} className="flex-shrink-0" />
          <span className="truncate max-w-24">{weatherData.location}</span>
        </div>
      )}
      {weatherData?.description && (
        <div className="flex items-center gap-1 text-sm text-slate-500 whitespace-nowrap">
          <Cloud size={14} className="flex-shrink-0" />
          <span className="capitalize truncate max-w-24">{weatherData.description}</span>
        </div>
      )}
    </div>
  );
};
