import React, { useState, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { FlaskConical, Droplets, ThermometerSun, AlertTriangle, Calendar, ChevronDown, Flame } from 'lucide-react';
import { useAdafruit } from "@/lib/AdafruitContext";
import { AdafruitFeedData, FeedType } from "@/lib/types";
import { formatDate } from "@/lib/utils/date-utils";
import { format } from "date-fns";
import { Calendar as CalendarComponent } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface AdvancedTimeRangeChartProps {
  activeParameter: 'ph' | 'tds' | 'temp' | 'thermocouple' | 'humidity';
  compact?: boolean;
}

type DateRangeMode = 'preset' | 'singleDate' | 'dateRange';

const AdvancedTimeRangeChart: React.FC<AdvancedTimeRangeChartProps> = ({ activeParameter, compact = false }) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState('1D');
  const [hoverInfo, setHoverInfo] = useState<{ value: number; time: string } | null>(null);
  const [dateRangeMode, setDateRangeMode] = useState<DateRangeMode>('preset');
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [dateRange, setDateRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined
  });
  
  const { 
    phData, 
    tds1Data, 
    temperatureData,
    thermocoupleData,
    humidityData,
    useFahrenheit,
    sensorData
  } = useAdafruit();

  const timeRanges = [
    { id: '1D', label: '1D' },
    { id: '5D', label: '5D' },
    { id: '1M', label: '1M' },
    { id: '6M', label: '6M' },
    { id: 'YTD', label: 'YTD' },
    { id: '1Y', label: '1Y' },
    { id: '5Y', label: '5Y' },
    { id: 'MAX', label: 'Max' }
  ];

  const getParameterData = (): AdafruitFeedData[] => {
    console.log("Getting parameter data for:", activeParameter);
    
    switch (activeParameter) {
      case 'ph':
        return phData || [];
      case 'tds':
        return tds1Data || [];
      case 'temp':
        return temperatureData || [];
      case 'thermocouple':
        return thermocoupleData || [];
      case 'humidity':
        return humidityData || [];
      default:
        console.log("Unrecognized parameter:", activeParameter);
        return [];
    }
  };

  const processChartData = () => {
    const rawData = getParameterData();
    
    console.log(`Processing chart data for ${activeParameter}:`, rawData?.length || 0, "data points");
    
    if (!rawData || rawData.length === 0) {
      console.log("No data available for parameter:", activeParameter);
      return [];
    }
    
    const sortedData = [...rawData].sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
    
    let startDate: Date;
    let endDate: Date = new Date();
    
    if (dateRangeMode === 'singleDate' && selectedDate) {
      startDate = new Date(selectedDate);
      startDate.setHours(0, 0, 0, 0);
      
      endDate = new Date(selectedDate);
      endDate.setHours(23, 59, 59, 999);
    } else if (dateRangeMode === 'dateRange' && dateRange.from) {
      startDate = new Date(dateRange.from);
      startDate.setHours(0, 0, 0, 0);
      
      if (dateRange.to) {
        endDate = new Date(dateRange.to);
        endDate.setHours(23, 59, 59, 999);
      } else {
        endDate = new Date(startDate);
        endDate.setHours(23, 59, 59, 999);
      }
    } else {
      switch(selectedTimeRange) {
        case '1D':
          startDate = new Date(endDate);
          startDate.setDate(startDate.getDate() - 1);
          break;
        case '5D':
          startDate = new Date(endDate);
          startDate.setDate(startDate.getDate() - 5);
          break;
        case '1M':
          startDate = new Date(endDate);
          startDate.setMonth(startDate.getMonth() - 1);
          break;
        case '6M':
          startDate = new Date(endDate);
          startDate.setMonth(startDate.getMonth() - 6);
          break;
        case 'YTD':
          startDate = new Date(endDate.getFullYear(), 0, 1);
          break;
        case '1Y':
          startDate = new Date(endDate);
          startDate.setFullYear(startDate.getFullYear() - 1);
          break;
        case '5Y':
          startDate = new Date(endDate);
          startDate.setFullYear(startDate.getFullYear() - 5);
          break;
        case 'MAX':
        default:
          startDate = new Date(0);
          break;
      }
    }
    
    console.log(`Date range: ${startDate.toISOString()} to ${endDate.toISOString()}`);
    
    const filteredData = sortedData.filter(item => {
      const itemDate = new Date(item.created_at);
      return itemDate >= startDate && itemDate <= endDate;
    });
    
    console.log(`Filtered data points: ${filteredData.length} (out of ${sortedData.length})`);
    
    if (filteredData.length === 0) {
      console.log("No data found within selected date range");
    }
    
    return filteredData.map(item => {
      const time = formatDate(item.created_at);
      const value = parseFloat(item.value);
      
      let adjustedValue = value;
      if ((activeParameter === 'temp' || activeParameter === 'thermocouple') && !useFahrenheit) {
        adjustedValue = (value - 32) * 5/9;
      }
      
      return {
        time,
        rawTime: new Date(item.created_at),
        [activeParameter]: adjustedValue
      };
    }).reverse();
  };

  const chartData = useMemo(() => 
    processChartData(), 
  [activeParameter, selectedTimeRange, phData, tds1Data, temperatureData, thermocoupleData, useFahrenheit, dateRangeMode, selectedDate, dateRange]);

  const getChartColor = (param: string) => {
    switch(param) {
      case 'ph': return '#10b981';
      case 'tds': return '#8b5cf6';
      case 'temp': return '#f59e0b';
      case 'thermocouple': return '#dc2626';
      case 'humidity': return '#3b82f6';
      default: return '#10b981';
    }
  };

  const getParamLabel = (param: string) => {
    switch(param) {
      case 'ph': return 'pH Level';
      case 'tds': return 'TDS (ppm)';
      case 'temp': return `Air Temperature (${useFahrenheit ? '°F' : '°C'})`;
      case 'thermocouple': return `Process Temperature (${useFahrenheit ? '°F' : '°C'})`;
      case 'humidity': return 'Humidity (%)';
      default: return 'Value';
    }
  };

  const getParameterIcon = () => {
    switch (activeParameter) {
      case 'ph': 
        return <FlaskConical size={40} className="mx-auto opacity-30" />;
      case 'tds': 
        return <Droplets size={40} className="mx-auto opacity-30" />;
      case 'temp': 
        return <ThermometerSun size={40} className="mx-auto opacity-30" />;
      case 'thermocouple': 
        return <Flame size={40} className="mx-auto opacity-30" />;
      case 'humidity':
        return <Droplets size={40} className="mx-auto opacity-30" />;
      default:
        return <ThermometerSun size={40} className="mx-auto opacity-30" />;
    }
  };

  const getCurrentValue = (): number | undefined => {
    switch(activeParameter) {
      case 'ph':
        return typeof sensorData.ph === 'number' ? sensorData.ph : undefined;
      case 'tds':
        return typeof sensorData.tds1 === 'number' ? sensorData.tds1 : undefined;
      case 'temp':
        if (typeof sensorData.temperatureF === 'number') {
          return useFahrenheit ? 
            sensorData.temperatureF : 
            ((sensorData.temperatureF - 32) * 5/9);
        }
        return undefined;
      case 'thermocouple':
        if (typeof sensorData.thermocoupleF === 'number') {
          return useFahrenheit ? 
            sensorData.thermocoupleF : 
            ((sensorData.thermocoupleF - 32) * 5/9);
        }
        return undefined;
      case 'humidity':
        return typeof sensorData.humidity === 'number' ? sensorData.humidity : undefined;
      default:
        return undefined;
    }
  };

  const getDataStats = () => {
    if (chartData.length === 0) {
      return { min: 0, max: 0, avg: 0, open: 0 };
    }
    
    const values = chartData.map(d => {
      const val = d[activeParameter];
      return typeof val === 'number' ? val : 0;
    });
    
    const min = Math.min(...values);
    const max = Math.max(...values);
    const avg = values.reduce((sum, val) => sum + val, 0) / values.length;
    const open = values[0];
    
    return { min, max, avg, open };
  };

  const stats = getDataStats();

  const currentValue: number = getCurrentValue() ?? (
    chartData.length > 0 ? 
      (typeof chartData[chartData.length - 1][activeParameter] === 'number' ? 
        chartData[chartData.length - 1][activeParameter] as number : 
        (activeParameter === 'ph' ? 7 : activeParameter === 'tds' ? 350 : useFahrenheit ? 73 : 23)
      ) : 
      (activeParameter === 'ph' ? 7 : activeParameter === 'tds' ? 350 : useFahrenheit ? 73 : 23)
  );

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const value = payload[0].value;
      return (
        <div className="bg-gray-800 p-2 rounded text-white text-xs shadow-md">
          <p className="font-medium">{payload[0].payload.time}</p>
          <p className="text-sm font-bold" style={{ color: getChartColor(activeParameter) }}>
            {typeof value === 'number' ? value.toFixed(1) : '0.0'}
            <span className="ml-1 text-xs font-normal text-gray-300">
              {activeParameter === 'ph' ? '' : 
               activeParameter === 'tds' ? ' ppm' : 
               activeParameter === 'humidity' ? ' %' :
               useFahrenheit ? ' °F' : ' °C'}
            </span>
          </p>
        </div>
      );
    }
    return null;
  };

  const handleMouseMove = (e: any) => {
    if (e && e.activePayload && e.activePayload.length) {
      const value = e.activePayload[0].value;
      if (typeof value === 'number') {
        setHoverInfo({
          value,
          time: e.activePayload[0].payload.time
        });
      }
    }
  };

  const handleMouseLeave = () => {
    setHoverInfo(null);
  };

  const getCurrentValueLabel = () => {
    const value = hoverInfo ? hoverInfo.value : currentValue;
    
    return (
      <span>
        {typeof value === 'number' ? value.toFixed(1) : '—'}
        <span className="text-sm text-slate-400 ml-1">
          {activeParameter === 'ph' ? '' : 
           activeParameter === 'tds' ? 'ppm' : 
           activeParameter === 'humidity' ? '%' : 
           useFahrenheit ? '°F' : '°C'}
        </span>
      </span>
    );
  };

  const handleDateSelect = (date: Date | undefined) => {
    setSelectedDate(date);
    if (date) {
      setDateRangeMode('singleDate');
    }
  };

  const handleDateRangeSelect = (range: { from: Date | undefined; to: Date | undefined }) => {
    setDateRange(range);
    if (range.from) {
      setDateRangeMode('dateRange');
    }
  };

  const switchToPresetRanges = (rangeId: string) => {
    console.log(`Switching to preset range: ${rangeId} (current mode: ${dateRangeMode})`);
    setSelectedTimeRange(rangeId);
    setDateRangeMode('preset');
    
    setSelectedDate(undefined);
    setDateRange({ from: undefined, to: undefined });
  };

  const getDateSelectionLabel = () => {
    if (dateRangeMode === 'singleDate' && selectedDate) {
      return format(selectedDate, 'MMM d, yyyy');
    } else if (dateRangeMode === 'dateRange') {
      if (dateRange.from && dateRange.to) {
        return `${format(dateRange.from, 'MMM d')} - ${format(dateRange.to, 'MMM d, yyyy')}`;
      } else if (dateRange.from) {
        return format(dateRange.from, 'MMM d, yyyy');
      }
    }
    return 'Select Date';
  };

  const EmptyDataPlaceholder = () => (
    <div className="h-full w-full flex items-center justify-center bg-slate-50 dark:bg-slate-900/20 rounded-lg">
      <div className="text-center p-4">
        <div className="mb-2 text-slate-400">
          {getParameterIcon()}
        </div>
        <h3 className="text-sm font-medium text-slate-600 dark:text-slate-300">No Data Available</h3>
        <p className="text-xs text-slate-500 mt-1">
          No historical data is available for this parameter within the selected time range.
        </p>
      </div>
    </div>
  );

  const getTimeSelectionLabel = () => {
    if (dateRangeMode === 'preset') {
      const selectedRange = timeRanges.find(range => range.id === selectedTimeRange);
      return selectedRange ? selectedRange.label : '1D';
    } else {
      return getDateSelectionLabel();
    }
  };

  return (
    <div className="bg-white dark:bg-slate-800 rounded-lg h-full">
      <div className={compact ? "p-2" : "p-4"}>
        <div className="mb-2 flex flex-col sm:flex-row sm:items-center justify-between">
          <div>
            <h3 className={`${compact ? "text-sm" : "text-lg"} font-medium mb-0 flex items-center gap-1`}>
              <span>{getParamLabel(activeParameter)}</span>
              <span className={`${compact ? "text-base" : "text-xl"} font-bold ml-1`}>{getCurrentValueLabel()}</span>
              {hoverInfo && <span className="text-xs text-slate-500 ml-1">{hoverInfo.time}</span>}
            </h3>
          </div>
          
          <div className="flex gap-1 mt-1 sm:mt-0">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm" className="flex items-center gap-1 text-xs h-7">
                  <Calendar size={12} />
                  <span className="hidden sm:inline">Date</span>
                  <ChevronDown size={12} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-48">
                <DropdownMenuItem 
                  className="text-xs"
                  onSelect={() => setDateRangeMode('singleDate')}
                >
                  Select specific date
                </DropdownMenuItem>
                <DropdownMenuItem 
                  className="text-xs"
                  onSelect={() => setDateRangeMode('dateRange')}
                >
                  Select date range
                </DropdownMenuItem>
                <DropdownMenuItem 
                  className="text-xs"
                  onSelect={() => {
                    setDateRangeMode('preset');
                    setSelectedDate(undefined);
                    setDateRange({ from: undefined, to: undefined });
                  }}
                >
                  Use preset ranges
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            
            {dateRangeMode === 'singleDate' && (
              <Popover>
                <PopoverTrigger asChild>
                  <Button 
                    variant="outline" 
                    size="sm"
                    className="flex items-center gap-1 text-xs h-7"
                  >
                    {selectedDate ? format(selectedDate, 'MMM d, yyyy') : 'Select date'}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="end">
                  <CalendarComponent
                    mode="single"
                    selected={selectedDate}
                    onSelect={handleDateSelect}
                    initialFocus
                    className="p-3 pointer-events-auto"
                  />
                </PopoverContent>
              </Popover>
            )}
            
            {dateRangeMode === 'dateRange' && (
              <Popover>
                <PopoverTrigger asChild>
                  <Button 
                    variant="outline" 
                    size="sm"
                    className="flex items-center gap-1 text-xs h-7"
                  >
                    {dateRange.from ? (
                      dateRange.to ? (
                        `${format(dateRange.from, 'MMM d')} - ${format(dateRange.to, 'MMM d')}`
                      ) : (
                        format(dateRange.from, 'MMM d, yyyy')
                      )
                    ) : (
                      'Select range'
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="end">
                  <CalendarComponent
                    mode="range"
                    selected={{
                      from: dateRange.from,
                      to: dateRange.to,
                    }}
                    onSelect={handleDateRangeSelect}
                    initialFocus
                    className="p-3 pointer-events-auto"
                  />
                </PopoverContent>
              </Popover>
            )}
            
            {dateRangeMode === 'preset' && (
              <div className="flex gap-1 overflow-x-auto py-0">
                {timeRanges.map(range => (
                  <button
                    key={range.id}
                    onClick={() => switchToPresetRanges(range.id)}
                    className={`px-2 py-1 rounded-md text-xs font-medium transition-colors h-7 ${
                      selectedTimeRange === range.id
                        ? 'bg-blue-100 text-blue-700 dark:bg-blue-900/30 dark:text-blue-400'
                        : 'text-slate-600 hover:bg-slate-100 dark:text-slate-400 dark:hover:bg-slate-700/30'
                    }`}
                  >
                    {range.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        {chartData.length > 0 ? (
          <div className={`${compact ? "h-[220px]" : "h-[260px]"} w-full bg-slate-50 dark:bg-slate-900/20 rounded-lg p-2`}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={chartData}
                margin={{ top: 5, right: 20, left: 0, bottom: 5 }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" vertical={false} opacity={0.4} />
                <XAxis 
                  dataKey="time" 
                  tick={{ fill: '#6b7280', fontSize: 10 }}
                  axisLine={{ stroke: '#d1d5db' }}
                  tickLine={{ stroke: '#d1d5db' }}
                  minTickGap={30}
                  padding={{ left: 5, right: 5 }}
                  height={compact ? 15 : 20}
                />
                <YAxis 
                  domain={['auto', 'auto']}
                  tick={{ fill: '#6b7280', fontSize: 10 }}
                  axisLine={{ stroke: '#d1d5db' }}
                  tickLine={{ stroke: '#d1d5db' }}
                  width={30}
                  tickCount={5}
                />
                <Tooltip content={<CustomTooltip />} />
                <Line 
                  type="monotone" 
                  dataKey={activeParameter} 
                  stroke={getChartColor(activeParameter)} 
                  strokeWidth={2} 
                  dot={false} 
                  activeDot={{ r: 4, strokeWidth: 1, stroke: getChartColor(activeParameter), fill: '#fff' }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <EmptyDataPlaceholder />
        )}

        <div className="grid grid-cols-4 gap-2 mt-2 text-xs">
          <div className="flex flex-col">
            <span className="text-slate-500 text-[10px]">Open</span>
            <span className="font-medium">{chartData.length > 0 ? stats.open.toFixed(1) : "—"}</span>
          </div>
          <div className="flex flex-col">
            <span className="text-slate-500 text-[10px]">High</span>
            <span className="font-medium">{chartData.length > 0 ? stats.max.toFixed(1) : "—"}</span>
          </div>
          <div className="flex flex-col">
            <span className="text-slate-500 text-[10px]">Low</span>
            <span className="font-medium">{chartData.length > 0 ? stats.min.toFixed(1) : "—"}</span>
          </div>
          <div className="flex flex-col">
            <span className="text-slate-500 text-[10px]">Average</span>
            <span className="font-medium">{chartData.length > 0 ? stats.avg.toFixed(1) : "—"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedTimeRangeChart;

