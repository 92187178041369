
import React from 'react';
import { useAdafruit } from "@/lib/AdafruitContext";
import { LoadingOverlay } from "@/components/LoadingOverlay";
import { RefreshButton } from "@/components/RefreshButton";
import { Card, CardContent } from "@/components/ui/card";
import { Calendar, Download } from "lucide-react";
import { formatTime } from "@/lib/utils/date-utils";
import { LocationTemperatureWidget } from "@/components/LocationTemperatureWidget";
import { TemperatureCircle } from "@/components/TemperatureCircle";
import AdvancedTimeRangeChart from "@/components/AdvancedTimeRangeChart";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { FeedType } from "@/lib/types";
import { Button } from "@/components/ui/button";

export default function HumidityPage() {
  const { sensorData, isLoading, fetchSensorData, lastUpdated, humidityData } = useAdafruit();

  if (isLoading) {
    return <LoadingOverlay />;
  }

  // Function to handle exporting humidity data
  const handleExport = () => {
    if (!humidityData || humidityData.length === 0) {
      console.error("No humidity data available to export");
      return;
    }

    // Format data for CSV export
    const csvContent = [
      // CSV Header
      ["Timestamp", "Humidity (%)"],
      // CSV Data rows
      ...humidityData.map(data => [
        new Date(data.created_at).toLocaleString(),
        data.value
      ])
    ]
      .map(row => row.join(","))
      .join("\n");

    // Create a Blob and download link
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `humidity-data-${new Date().toISOString().slice(0, 10)}.csv`);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="space-y-6 max-w-6xl mx-auto">
      {/* Header - Standardized with other pages */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
        <div>
          <h1 className="text-3xl font-bold text-blue-500 mb-1">
            Humidity Dashboard
          </h1>
          <p className="text-slate-500 mt-1">Advanced moisture monitoring for precise environmental control</p>
        </div>
        <div className="flex items-center gap-3">
          <LocationTemperatureWidget />
          {/* Export Button */}
          <Button 
            variant="outline" 
            className="flex gap-1 items-center" 
            onClick={handleExport}
          >
            <Download className="h-4 w-4" />
            Export
          </Button>
          <RefreshButton onClick={fetchSensorData} isLoading={isLoading} />
        </div>
      </div>

      {/* Main Content */}
      {/* Centered Humidity Reading */}
      <div className="flex justify-center items-center mb-12">
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-xl font-semibold text-blue-600 mb-4">
            Current Humidity
          </h3>
          {sensorData.humidity !== undefined ? (
            <TemperatureCircle
              title=""
              value={sensorData.humidity}
              useFahrenheit={false}
              feedType={FeedType.Humidity}
              size="large"
            />
          ) : (
            <Alert variant="destructive" className="mt-2">
              <AlertTitle className="text-sm">No Data</AlertTitle>
              <AlertDescription className="text-xs">
                Unable to load humidity data.
              </AlertDescription>
            </Alert>
          )}
        </div>
      </div>

      {/* Chart Card - Full Width */}
      <Card className="border-blue-100 dark:border-blue-900/40 shadow-sm overflow-hidden mb-4">
        <CardContent className="pt-4">
          <div className="h-[400px]">
            <AdvancedTimeRangeChart activeParameter="humidity" />
          </div>
        </CardContent>
      </Card>

      {/* Last Updated Footer */}
      {lastUpdated && (
        <div className="flex justify-center mt-2">
          <div className="flex items-center gap-1 text-xs text-slate-500">
            <Calendar className="h-3 w-3" />
            <span>Last updated: {formatTime(lastUpdated.toString())}</span>
          </div>
        </div>
      )}
    </div>
  );
}
