
import { useState } from "react";
import { Layers, Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { PasswordDialog } from "@/components/PasswordDialog";

export default function WelcomePage() {
  const navigate = useNavigate();
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

  const handleSuccess = () => {
    setPasswordDialogOpen(false);
    navigate("/dashboard");
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-[#f0f4ff] to-white text-[#333]">
      <div className="container max-w-[1200px] mx-auto px-5">
        {/* Navigation */}
        <nav className="flex justify-between items-center py-5">
          <a href="/" className="text-2xl font-bold text-[#5664e0] flex items-center gap-2">
            <Layers className="w-8 h-8" />
            Avenix
          </a>
        </nav>
      </div>
      
      {/* Hero section with improved styling */}
      <section className="flex-grow py-12 flex flex-col items-center justify-center text-center">
        <div className="container px-4">
          <h1 className="text-4xl md:text-5xl font-extrabold text-[#5664e0] mb-5">Welcome to Avenix</h1>
          <p className="text-lg md:text-xl text-[#6c757d] max-w-[800px] mx-auto mb-5">
            Advanced sensor monitoring for real-time data analysis and environmental tracking
          </p>
          
          {/* Custom divider */}
          <div className="w-20 h-1 bg-[#36b3a8] mx-auto my-5 rounded"></div>
          
          <div className="dashboard-button-container my-8">
            <button 
              onClick={() => setPasswordDialogOpen(true)}
              className="bg-[#36b3a8] hover:bg-[#2aa39a] text-white border border-[#36b3a8] rounded-md font-medium text-lg px-10 py-6 inline-flex items-center justify-center gap-2 transform hover:-translate-y-1 transition-all duration-300 shadow-md hover:shadow-lg"
            >
              <Plus className="w-5 h-5" />
              Enter Dashboard
            </button>
          </div>
        </div>
      </section>
      
      {/* Features section */}
      <section className="pb-10 md:pb-16">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl font-bold text-center mb-8">Monitor Your Environment</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {/* Temperature Card */}
            <div className="bg-white p-4 rounded-xl shadow-sm hover:shadow-md hover:-translate-y-1 transition-all duration-300 flex flex-col items-center text-center">
              <div className="w-[45px] h-[45px] bg-[#f0f4ff] rounded-full flex items-center justify-center mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-[30px] h-[30px] text-[#5664e0]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M14 14.76V3.5a2.5 2.5 0 0 0-5 0v11.26a4.5 4.5 0 1 0 5 0z"></path>
                </svg>
              </div>
              <h3 className="text-lg font-semibold mb-1">Temperature</h3>
              <p className="text-[#6c757d] text-sm leading-relaxed">
                Track temperature changes with high precision sensors and receive alerts for unusual patterns.
              </p>
            </div>
            
            {/* Humidity Card */}
            <div className="bg-white p-4 rounded-xl shadow-sm hover:shadow-md hover:-translate-y-1 transition-all duration-300 flex flex-col items-center text-center">
              <div className="w-[45px] h-[45px] bg-[#f0f4ff] rounded-full flex items-center justify-center mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-[30px] h-[30px] text-[#5664e0]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z"></path>
                </svg>
              </div>
              <h3 className="text-lg font-semibold mb-1">Humidity</h3>
              <p className="text-[#6c757d] text-sm leading-relaxed">
                Monitor humidity levels in real-time with detailed analytics and historical comparisons.
              </p>
            </div>
            
            {/* Water Quality Card */}
            <div className="bg-white p-4 rounded-xl shadow-sm hover:shadow-md hover:-translate-y-1 transition-all duration-300 flex flex-col items-center text-center">
              <div className="w-[45px] h-[45px] bg-[#f0f4ff] rounded-full flex items-center justify-center mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-[30px] h-[30px] text-[#5664e0]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"></path>
                </svg>
              </div>
              <h3 className="text-lg font-semibold mb-1">Water Quality</h3>
              <p className="text-[#6c757d] text-sm leading-relaxed">
                Measure key water quality parameters including pH, turbidity, and dissolved oxygen.
              </p>
            </div>
            
            {/* Knowledge Card */}
            <div className="bg-white p-4 rounded-xl shadow-sm hover:shadow-md hover:-translate-y-1 transition-all duration-300 flex flex-col items-center text-center">
              <div className="w-[45px] h-[45px] bg-[#f0f4ff] rounded-full flex items-center justify-center mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-[30px] h-[30px] text-[#5664e0]" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M12 2v6M12 18v4M4.93 4.93l4.24 4.24M14.83 14.83l4.24 4.24M2 12h6M16 12h6M4.93 19.07l4.24-4.24M14.83 9.17l4.24-4.24"></path>
                </svg>
              </div>
              <h3 className="text-lg font-semibold mb-1">Knowledge</h3>
              <p className="text-[#6c757d] text-sm leading-relaxed">
                Access insights and recommendations based on collected data to optimize environmental conditions.
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <footer className="py-6 text-sm text-center text-[#6c757d]">
        © {new Date().getFullYear()} Avenix. All rights reserved.
      </footer>

      <PasswordDialog
        open={passwordDialogOpen}
        onOpenChange={setPasswordDialogOpen}
        onSuccess={handleSuccess}
      />
    </div>
  );
}
