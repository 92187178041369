
import { useAdafruit } from "@/lib/AdafruitContext";
import { UnitToggle } from "@/components/UnitToggle";
import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Slider } from "@/components/ui/slider";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { RefreshCw, Info, User } from "lucide-react";
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";

export default function SettingsPage() {
  const {
    autoRefresh,
    setAutoRefresh,
    refreshInterval,
    setRefreshInterval,
    useFahrenheit,
    setUseFahrenheit,
    useMetric,
    setUseMetric,
    fetchSensorData
  } = useAdafruit();
  
  const [serverAddress, setServerAddress] = useState(localStorage.getItem('serverAddress') || '192.168.1.75');
  const { toast } = useToast();
  
  const handleRefreshChange = (value: number[]) => {
    setRefreshInterval(value[0]);
  };
  
  const handleApplySettings = () => {
    localStorage.setItem('serverAddress', serverAddress);
    fetchSensorData();
    
    toast({
      title: "Settings Updated",
      description: "Your settings have been successfully applied",
    });
  };
  
  return (
    <div className="container max-w-2xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Settings</h1>
      
      <div className="space-y-8">
        <Card className="p-6">
          <h2 className="text-lg font-medium mb-4">Connection</h2>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="serverAddress">ESP32 IP Address</Label>
              <Input 
                id="serverAddress" 
                value={serverAddress} 
                onChange={(e) => setServerAddress(e.target.value)}
                className="max-w-md"
              />
            </div>
          </div>
        </Card>
        
        <Card className="p-6">
          <h2 className="text-lg font-medium mb-4">Refresh Settings</h2>
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <Label htmlFor="autoRefresh">Auto-refresh</Label>
              <Switch 
                id="autoRefresh" 
                checked={autoRefresh} 
                onCheckedChange={setAutoRefresh}
              />
            </div>
            
            {autoRefresh && (
              <div className="space-y-3">
                <div className="flex justify-between">
                  <Label>Refresh Every</Label>
                  <span className="text-blue-500 font-medium">{refreshInterval} seconds</span>
                </div>
                
                <Slider 
                  value={[refreshInterval]} 
                  min={1} 
                  max={60} 
                  step={1} 
                  onValueChange={handleRefreshChange}
                  className="max-w-md"
                />
                <p className="text-sm text-slate-500 italic">
                  Changes to refresh settings are applied immediately
                </p>
              </div>
            )}
            
            <Button className="w-full" onClick={handleApplySettings}>
              <RefreshCw className="mr-2 h-4 w-4" />
              Apply Settings
            </Button>
          </div>
        </Card>
        
        <Card className="p-6">
          <h2 className="text-lg font-medium mb-4">Measurement Units</h2>
          <div className="space-y-6">
            <div className="space-y-2">
              <Label>Temperature Unit</Label>
              <UnitToggle 
                value={useFahrenheit} 
                onChange={setUseFahrenheit} 
                labels={["Fahrenheit (°F)", "Celsius (°C)"]} 
              />
            </div>
            
            <div className="space-y-2">
              <Label>Distance Unit</Label>
              <UnitToggle 
                value={!useMetric} 
                onChange={(value) => setUseMetric(!value)} 
                labels={["Inches (in)", "Centimeters (cm)"]} 
              />
            </div>
          </div>
        </Card>
        
        <Card className="p-6">
          <h2 className="text-lg font-medium mb-4">About</h2>
          <div className="space-y-4">
            <div className="flex justify-between items-center py-2">
              <div className="flex items-center gap-2">
                <Info className="h-4 w-4 text-gray-500" />
                <span>Version</span>
              </div>
              <span className="text-gray-500">1.0.0</span>
            </div>
            
            <div className="flex justify-between items-center py-2">
              <div className="flex items-center gap-2">
                <User className="h-4 w-4 text-gray-500" />
                <span>Developer</span>
              </div>
              <span className="text-gray-500">Christopher Fishburn</span>
            </div>
            
            <div className="pt-2">
              <p className="text-sm text-gray-500">ESP32 Multi-Sensor Monitoring System</p>
              <p className="text-xs text-gray-500 mt-1">
                Built {new Date().toLocaleDateString()}
              </p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
