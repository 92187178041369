import React from 'react';
import { useAdafruit } from "@/lib/AdafruitContext";
import AdvancedTimeRangeChart from './AdvancedTimeRangeChart';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { TemperatureCircle } from "@/components/TemperatureCircle";
import { FeedType } from "@/lib/types";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { RefreshCw, AlertTriangle } from "lucide-react";

interface TemperatureTimeRangeChartProps {
  showCircles?: boolean;
  initialTab?: 'temp' | 'thermocouple';
}

const TemperatureTimeRangeChart: React.FC<TemperatureTimeRangeChartProps> = ({ 
  showCircles = true,
  initialTab = 'temp'
}) => {
  const { useFahrenheit, sensorData, error, fetchSensorData, isLoading } = useAdafruit();
  const [activeParameter, setActiveParameter] = React.useState<'temp' | 'thermocouple'>(initialTab);

  return (
    <div className="space-y-6">
      {/* Temperature Type Selector */}
      <Tabs 
        defaultValue={initialTab} 
        onValueChange={(value) => setActiveParameter(value as 'temp' | 'thermocouple')}
        className="w-full"
      >
        <TabsList className="grid w-full grid-cols-2 mb-4">
          <TabsTrigger value="temp">Air Temperature</TabsTrigger>
          <TabsTrigger value="thermocouple">Process Temperature</TabsTrigger>
        </TabsList>

        <TabsContent value="temp" className="space-y-6">
          {error && error.includes('temperature') && (
            <Alert variant="destructive" className="mb-4">
              <AlertTriangle className="h-4 w-4" />
              <AlertTitle>Connection Error</AlertTitle>
              <AlertDescription>
                <p>{error}</p>
                <Button 
                  variant="outline" 
                  size="sm" 
                  className="mt-2"
                  onClick={fetchSensorData}
                  disabled={isLoading}
                >
                  <RefreshCw className={`h-4 w-4 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
                  Retry Connection
                </Button>
              </AlertDescription>
            </Alert>
          )}
          
          {showCircles && sensorData.temperatureF !== undefined && (
            <div className="flex justify-center mb-6">
              <TemperatureCircle 
                title="Air Temperature" 
                value={sensorData.temperatureF} 
                useFahrenheit={useFahrenheit}
                feedType={FeedType.Temperature}
              />
            </div>
          )}
          
          <AdvancedTimeRangeChart activeParameter="temp" />
        </TabsContent>

        <TabsContent value="thermocouple" className="space-y-6">
          {error && error.includes('thermocouple') && (
            <Alert variant="destructive" className="mb-4">
              <AlertTriangle className="h-4 w-4" />
              <AlertTitle>Connection Error</AlertTitle>
              <AlertDescription>
                <p>{error}</p>
                <Button 
                  variant="outline" 
                  size="sm" 
                  className="mt-2"
                  onClick={fetchSensorData}
                  disabled={isLoading}
                >
                  <RefreshCw className={`h-4 w-4 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
                  Retry Connection
                </Button>
              </AlertDescription>
            </Alert>
          )}
          
          {showCircles && sensorData.thermocoupleF !== undefined && (
            <div className="flex justify-center mb-6">
              <TemperatureCircle 
                title="Process Temperature" 
                value={sensorData.thermocoupleF} 
                useFahrenheit={useFahrenheit}
                feedType={FeedType.Thermocouple}
              />
            </div>
          )}
          
          <AdvancedTimeRangeChart activeParameter="thermocouple" />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default TemperatureTimeRangeChart;
