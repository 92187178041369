
import React, { Component, ErrorInfo, ReactNode } from "react";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { RefreshCw } from "lucide-react";
import { Button } from "@/components/ui/button";

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { 
      hasError: true, 
      error,
      errorInfo: null
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    this.setState({ errorInfo });
  }

  public render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      }
      
      return (
        <div className="p-6 mx-auto max-w-2xl mt-8">
          <Alert variant="destructive">
            <AlertTitle className="text-xl font-bold">Something went wrong</AlertTitle>
            <AlertDescription>
              <div className="mt-2">
                <p className="mb-4">The application encountered an error. Please try refreshing the page.</p>
                
                <details className="mt-4 text-sm">
                  <summary className="cursor-pointer font-medium">Show error details</summary>
                  <div className="mt-2 bg-slate-100 dark:bg-slate-800 p-4 rounded-md overflow-auto max-h-[300px] text-xs">
                    <p className="font-bold mb-2">Error:</p>
                    <pre className="whitespace-pre-wrap">{this.state.error?.toString()}</pre>
                    
                    {this.state.errorInfo && (
                      <>
                        <p className="font-bold mt-4 mb-2">Component Stack:</p>
                        <pre className="whitespace-pre-wrap">{this.state.errorInfo.componentStack}</pre>
                      </>
                    )}
                  </div>
                </details>
                
                <Button 
                  onClick={() => window.location.reload()} 
                  className="mt-4 bg-red-600 hover:bg-red-700"
                >
                  <RefreshCw className="w-4 h-4 mr-2" />
                  Refresh Page
                </Button>
              </div>
            </AlertDescription>
          </Alert>
        </div>
      );
    }

    return this.props.children;
  }
}
