
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";

interface UnitToggleProps {
  value: boolean;
  onChange: (value: boolean) => void;
  labels: [string, string];
}

export function UnitToggle({ value, onChange, labels }: UnitToggleProps) {
  const [selectedValue, setSelectedValue] = useState<string>(value ? "true" : "false");

  const handleValueChange = (newValue: string) => {
    if (newValue) {
      setSelectedValue(newValue);
      onChange(newValue === "true");
    }
  };

  return (
    <ToggleGroup
      type="single"
      value={selectedValue}
      onValueChange={handleValueChange}
      className="rounded-md border overflow-hidden"
    >
      <ToggleGroupItem 
        value="true" 
        className={`flex-1 ${selectedValue === "true" ? "bg-blue-500 text-white" : ""}`}
      >
        {labels[0]}
      </ToggleGroupItem>
      <ToggleGroupItem 
        value="false" 
        className={`flex-1 ${selectedValue === "false" ? "bg-blue-500 text-white" : ""}`}
      >
        {labels[1]}
      </ToggleGroupItem>
    </ToggleGroup>
  );
}
