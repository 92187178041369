
import { Card } from "@/components/ui/card";
import { feedTypeConfigs, FeedType } from "@/lib/types";
import { useAdafruit } from "@/lib/AdafruitContext";
import { Link } from "react-router-dom";
import { 
  Thermometer, 
  Droplets, 
  Ruler, 
  Flame,
  Waves,
  FlaskConical,
  LucideProps 
} from "lucide-react";
import { cn } from "@/lib/utils";

interface SensorCardProps {
  title: string;
  value?: number;
  feedType: FeedType;
  convertedValue?: number;
}

// Map of feed types to their respective icons
const iconMap = {
  [FeedType.Temperature]: Thermometer,
  [FeedType.Humidity]: Droplets,
  [FeedType.Distance]: Ruler,
  [FeedType.Thermocouple]: Flame,
  [FeedType.TDS1]: Waves,
  [FeedType.TDS2]: Waves,
  [FeedType.PH]: FlaskConical
};

// Map feed types to their destination routes with query parameters
const routeMap = {
  [FeedType.Temperature]: "/temperature",
  [FeedType.Humidity]: "/humidity",
  [FeedType.Distance]: "/feed/distance",
  [FeedType.Thermocouple]: "/temperature?tab=thermocouple",
  [FeedType.TDS1]: "/water-quality?tab=tds",
  [FeedType.TDS2]: "/water-quality?tab=tds",
  [FeedType.PH]: "/water-quality?tab=ph"
};

export function SensorCard({ title, value, feedType, convertedValue }: SensorCardProps) {
  const { getUnit } = useAdafruit();
  const config = feedTypeConfigs[feedType];
  
  // Get the correct icon component for this feed type
  const IconComponent = iconMap[feedType];
  
  // Get the correct route for this feed type
  const route = routeMap[feedType];
  
  const displayValue = convertedValue !== undefined ? convertedValue : value;
  const unit = getUnit(feedType);
  const formattedValue = displayValue !== undefined ? displayValue.toFixed(1) : "--";
  
  return (
    <Link to={route} className="block">
      <Card className="h-full overflow-hidden transition-all duration-300 hover:shadow-xl hover:translate-y-[-2px] cursor-pointer border-0 shadow-lg">
        <div className="p-5 flex flex-col h-full gap-4">
          {/* Gradient top bar */}
          <div 
            className="h-1 w-full rounded-full -mt-1 mb-2"
            style={{
              background: `linear-gradient(to right, ${config.gradientStart}, ${config.gradientEnd})`
            }}
          />
          
          <div className="flex items-center gap-3">
            <div 
              className={cn(
                "w-10 h-10 rounded-full flex items-center justify-center",
                "bg-gradient-to-br from-white/80 to-white/30 dark:from-slate-800/80 dark:to-slate-900/30",
                "shadow-sm"
              )}
              style={{
                border: `1px solid ${config.gradientStart}30`
              }}
            >
              <IconComponent 
                className="h-5 w-5" 
                style={{ color: config.color }} 
              />
            </div>
            <h3 className="font-medium text-slate-700 dark:text-slate-300">{title}</h3>
          </div>
          
          <div className="mt-auto text-center py-4">
            <div 
              className="text-3xl font-bold tracking-tight"
              style={{ color: config.color }}
            >
              {formattedValue} <span className="text-lg text-slate-500 dark:text-slate-400">{unit}</span>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  );
}
