
import { Progress } from "@/components/ui/progress";

export function LoadingOverlay() {
  return (
    <div className="fixed inset-0 bg-black/40 backdrop-blur-md flex items-center justify-center z-50">
      <div className="bg-black/70 p-8 rounded-xl flex flex-col items-center gap-5 border border-white/10 shadow-2xl">
        <div className="relative w-16 h-16">
          <div className="absolute inset-0 rounded-full border-t-2 border-blue-400 animate-spin"></div>
          <div className="absolute inset-1 rounded-full border-r-2 border-purple-400 animate-spin animate-reverse"></div>
          <div className="absolute inset-2 rounded-full border-b-2 border-emerald-400 animate-spin animate-delay-500"></div>
          <div className="absolute inset-3 rounded-full border-l-2 border-pink-400 animate-spin animate-reverse animate-delay-500"></div>
        </div>
        <Progress className="w-48 h-2 bg-gray-800" value={33} />
        <p className="text-white font-medium">Updating Sensors...</p>
      </div>
    </div>
  );
}
