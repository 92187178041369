import { useAdafruit } from "@/lib/AdafruitContext";
import { LoadingOverlay } from "@/components/LoadingOverlay";
import { RefreshButton } from "@/components/RefreshButton";
import { formatTime, formatDate } from "@/lib/utils/date-utils";
import { Clock, Thermometer, Droplets, Ruler, Flame, Info, Download } from "lucide-react";
import { SensorCard } from "@/components/SensorCard";
import { Button } from "@/components/ui/button";
import { FeedType } from "@/lib/types";
import { Card } from "@/components/ui/card";
import { LocationTemperatureWidget } from "@/components/LocationTemperatureWidget";
import { useToast } from "@/hooks/use-toast";

export default function DashboardPage() {
  const { 
    lastUpdated, 
    isLoading, 
    error, 
    fetchSensorData,
    sensorData,
    useFahrenheit,
    useMetric,
    temperatureData,
    thermocoupleData,
    humidityData,
    distanceData,
    tds1Data,
    tds2Data,
    phData,
    getUnit
  } = useAdafruit();
  
  const { toast } = useToast();
  
  const handleExport = () => {
    // Create arrays for each sensor type with formatted data
    const tempData = temperatureData.map(item => ({
      parameter: 'Air Temperature',
      value: item.value,
      unit: getUnit(FeedType.Temperature),
      timestamp: formatDate(item.created_at)
    }));
    
    const thermoData = thermocoupleData.map(item => ({
      parameter: 'Process Temperature',
      value: item.value,
      unit: getUnit(FeedType.Thermocouple),
      timestamp: formatDate(item.created_at)
    }));
    
    const humidityRows = humidityData.map(item => ({
      parameter: 'Humidity',
      value: item.value,
      unit: getUnit(FeedType.Humidity),
      timestamp: formatDate(item.created_at)
    }));
    
    const distanceRows = distanceData.map(item => ({
      parameter: 'Distance',
      value: item.value,
      unit: getUnit(FeedType.Distance),
      timestamp: formatDate(item.created_at)
    }));
    
    const tds1Rows = tds1Data.map(item => ({
      parameter: 'TDS Sensor 1',
      value: item.value,
      unit: getUnit(FeedType.TDS1),
      timestamp: formatDate(item.created_at)
    }));
    
    const tds2Rows = tds2Data.map(item => ({
      parameter: 'TDS Sensor 2',
      value: item.value,
      unit: getUnit(FeedType.TDS2),
      timestamp: formatDate(item.created_at)
    }));
    
    const phRows = phData.map(item => ({
      parameter: 'pH Level',
      value: item.value,
      unit: getUnit(FeedType.PH),
      timestamp: formatDate(item.created_at)
    }));
    
    // Combine all data
    const combinedData = [
      ...tempData,
      ...thermoData,
      ...humidityRows,
      ...distanceRows,
      ...tds1Rows,
      ...tds2Rows,
      ...phRows
    ];
    
    // Convert to CSV
    const headers = ['Parameter', 'Value', 'Unit', 'Timestamp'];
    const csvContent = [
      headers.join(','),
      ...combinedData.map(row => 
        [
          row.parameter,
          row.value,
          row.unit,
          row.timestamp
        ].join(',')
      )
    ].join('\n');
    
    // Create and download the file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `sensor-data-${new Date().toISOString().split('T')[0]}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    toast({
      title: "Export Successful",
      description: "All sensor data has been exported to CSV",
    });
  };

  return (
    <div className="space-y-6 max-w-6xl mx-auto">
      {/* Header */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
        <div>
          <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            Sensor Dashboard
          </h1>
          <p className="text-slate-500 mt-1">Real-time monitoring and analysis</p>
        </div>
        <div className="flex items-center gap-3">
          {/* Local Temperature Widget */}
          <LocationTemperatureWidget />
          
          <Button 
            variant="outline" 
            className="flex gap-2 items-center"
            onClick={handleExport}
          >
            <Download size={16} />
            Export
          </Button>
          <RefreshButton onClick={fetchSensorData} isLoading={isLoading} />
        </div>
      </div>
      
      {error && (
        <Card className="bg-red-50 border-red-200 p-4">
          <div className="flex gap-3">
            <Info className="h-5 w-5 text-red-500 flex-shrink-0" />
            <p className="text-red-700 text-sm">{error}</p>
          </div>
        </Card>
      )}
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <SensorCard
          title="Air Temperature"
          value={sensorData.temperatureF}
          feedType={FeedType.Temperature}
          convertedValue={!useFahrenheit && sensorData.temperatureF !== undefined ? 
            ((sensorData.temperatureF - 32) * 5) / 9 : undefined}
        />
        
        <SensorCard
          title="Process Temperature"
          value={sensorData.thermocoupleF}
          feedType={FeedType.Thermocouple}
          convertedValue={!useFahrenheit && sensorData.thermocoupleF !== undefined ? 
            ((sensorData.thermocoupleF - 32) * 5) / 9 : undefined}
        />
        
        <SensorCard
          title="Humidity"
          value={sensorData.humidity}
          feedType={FeedType.Humidity}
        />
        
        {/* Distance card removed */}
        
        <SensorCard
          title="TDS Sensor 1"
          value={sensorData.tds1}
          feedType={FeedType.TDS1}
        />
        
        <SensorCard
          title="TDS Sensor 2"
          value={sensorData.tds2}
          feedType={FeedType.TDS2}
        />
        
        <SensorCard
          title="pH Level"
          value={sensorData.ph}
          feedType={FeedType.PH}
        />
      </div>
      
      <div className="pt-4 flex justify-center">
        {lastUpdated && (
          <div className="flex items-center gap-2 text-sm text-slate-500 py-2 px-4 bg-white dark:bg-slate-800 rounded-full shadow">
            <Clock className="h-3.5 w-3.5" />
            <span>Last updated: {formatTime(lastUpdated.toString())}</span>
          </div>
        )}
      </div>
      
      {isLoading && <LoadingOverlay />}
    </div>
  );
}
