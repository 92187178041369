
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAdafruit } from "@/lib/AdafruitContext";
import { SensorCard } from "@/components/SensorCard";
import { TemperatureCircle } from "@/components/TemperatureCircle";
import { LoadingOverlay } from "@/components/LoadingOverlay";
import { RefreshButton } from "@/components/RefreshButton";
import { FeedType, feedTypeConfigs, AdafruitFeedData } from "@/lib/types";
import { formatDate } from "@/lib/utils/date-utils";
import { Card } from "@/components/ui/card";
import { ArrowLeft, RefreshCw } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function FeedDetailPage() {
  const { feedType } = useParams<{ feedType: string }>();
  const navigate = useNavigate();
  
  const normalizeFeedType = (feedType?: string): FeedType => {
    if (!feedType) return FeedType.Temperature;
    
    // Convert to lowercase then capitalize first letter
    const normalizedFeedType = feedType.charAt(0).toUpperCase() + feedType.slice(1).toLowerCase();
    
    if (Object.values(FeedType).includes(normalizedFeedType as FeedType)) {
      return normalizedFeedType as FeedType;
    }
    
    return FeedType.Temperature;
  };
  
  const currentFeedType = normalizeFeedType(feedType);
  
  const { 
    distanceData, 
    humidityData, 
    temperatureData, 
    thermocoupleData,
    tds1Data,
    tds2Data,
    phData,
    sensorData,
    useFahrenheit,
    useMetric,
    getUnit,
    fetchSensorData,
    isLoading
  } = useAdafruit();
  
  // Refresh data when component mounts or feed type changes
  useEffect(() => {
    fetchSensorData();
    console.log("Current feed type:", currentFeedType);
    console.log("Current route: feed/" + feedType);
    console.log("Sensor data:", sensorData);
  }, [currentFeedType, fetchSensorData, feedType]);
  
  const getFeedData = (): AdafruitFeedData[] => {
    console.log("Getting feed data for:", currentFeedType);
    switch (currentFeedType) {
      case FeedType.Distance:
        return distanceData;
      case FeedType.Humidity:
        return humidityData;
      case FeedType.Temperature:
        return temperatureData;
      case FeedType.Thermocouple:
        return thermocoupleData;
      case FeedType.TDS1:
        return tds1Data;
      case FeedType.TDS2:
        return tds2Data;
      case FeedType.PH:
        return phData;
      default:
        return [];
    }
  };
  
  const feedData = getFeedData();
  const config = feedTypeConfigs[currentFeedType];
  
  const getChartData = () => {
    return feedData.slice(0, 15).map(item => ({
      time: formatDate(item.created_at),
      value: parseFloat(item.value)
    })).reverse();
  };
  
  // Fixed to correctly return the appropriate sensor value based on currentFeedType
  const getValue = () => {
    console.log("Getting value for:", currentFeedType);
    
    switch (currentFeedType) {
      case FeedType.Distance:
        return sensorData.distance;
      case FeedType.Humidity:
        return sensorData.humidity;
      case FeedType.Temperature:
        return sensorData.temperatureF;
      case FeedType.Thermocouple:
        return sensorData.thermocoupleF;
      case FeedType.TDS1:
        return sensorData.tds1;
      case FeedType.TDS2:
        return sensorData.tds2;
      case FeedType.PH:
        return sensorData.ph;
      default:
        return undefined;
    }
  };
  
  const getConvertedValue = () => {
    switch (currentFeedType) {
      case FeedType.Distance:
        if (useMetric && sensorData.distance !== undefined) {
          return sensorData.distance * 2.54;
        }
        return undefined;
      case FeedType.Temperature:
      case FeedType.Thermocouple:
        const value = currentFeedType === FeedType.Temperature 
          ? sensorData.temperatureF 
          : sensorData.thermocoupleF;
        
        if (!useFahrenheit && value !== undefined) {
          return ((value - 32) * 5) / 9;
        }
        return undefined;
      default:
        return undefined;
    }
  };
  
  // Only temperature and thermocouple should use the temperature circle
  const isTemperatureFeed = 
    currentFeedType === FeedType.Temperature || 
    currentFeedType === FeedType.Thermocouple;
  
  // Get the appropriate title for the sensor display
  const getSensorTitle = () => {
    switch (currentFeedType) {
      case FeedType.Temperature:
        return "Air Temperature";
      case FeedType.Thermocouple:
        return "Process Temperature";
      case FeedType.TDS1:
        return "TDS Sensor 1";
      case FeedType.TDS2:
        return "TDS Sensor 2";
      case FeedType.PH:
        return "pH Level";
      case FeedType.Distance:
        return "Distance";
      case FeedType.Humidity:
        return "Humidity";
      default:
        return currentFeedType;
    }
  };
  
  return (
    <div className="container max-w-2xl mx-auto p-4">
      <div className="flex items-center gap-2 mb-6">
        <Button 
          variant="ghost" 
          size="icon"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft className="h-5 w-5" />
        </Button>
        <h1 className="text-2xl font-bold">{getSensorTitle()}</h1>
      </div>
      
      <div className="mb-8">
        {isTemperatureFeed ? (
          <TemperatureCircle 
            title={getSensorTitle()}
            value={getValue()}
            useFahrenheit={useFahrenheit}
            feedType={currentFeedType}
          />
        ) : (
          <SensorCard 
            title={getSensorTitle()}
            value={getValue()}
            feedType={currentFeedType}
            convertedValue={getConvertedValue()}
          />
        )}
      </div>
      
      {feedData.length > 0 && (
        <div className="mb-8">
          <h2 className="text-lg font-semibold mb-4">{getSensorTitle()} Trend</h2>
          <Card className="p-4">
            <div className="h-[250px] w-full">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={getChartData()}>
                  <CartesianGrid strokeDasharray="3 3" opacity={0.3} />
                  <XAxis 
                    dataKey="time" 
                    tick={{ fontSize: 12 }}
                    tickMargin={8}
                    tickFormatter={(value) => {
                      // Show abbreviated times
                      return value.split(',')[1]?.trim() || value;
                    }}
                  />
                  <YAxis tick={{ fontSize: 12 }} />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke={config.color}
                    strokeWidth={2}
                    dot={{ fill: config.color, r: 4 }}
                    activeDot={{ r: 6, fill: config.color }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
      )}
      
      <div className="space-y-4 mb-8">
        <h2 className="text-lg font-semibold">Recent Data</h2>
        
        {feedData.slice(0, 10).map(item => (
          <Card key={item.id} className="p-4">
            <div className="flex justify-between items-center">
              <div>
                <div className="font-medium">
                  {item.value} {getUnit(currentFeedType)}
                </div>
                <div className="text-sm text-gray-500">
                  {formatDate(item.created_at)}
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
      
      <div className="flex justify-center mb-8">
        <Button 
          onClick={fetchSensorData}
          disabled={isLoading}
          className="bg-blue-500 hover:bg-blue-600"
        >
          <RefreshCw className="h-4 w-4 mr-2" />
          Refresh Data
        </Button>
      </div>
      
      {isLoading && <LoadingOverlay />}
    </div>
  );
}
