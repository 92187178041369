import React from 'react';
import { useAdafruit } from "@/lib/AdafruitContext";
import { LoadingOverlay } from "@/components/LoadingOverlay";
import { RefreshButton } from "@/components/RefreshButton";
import { Card, CardContent } from "@/components/ui/card";
import { formatDate } from "@/lib/utils/date-utils";
import { Clock, Info, BarChart, FlaskConical, Waves, ThermometerSun, Download } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { Skeleton } from "@/components/ui/skeleton";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { TemperatureCircle } from "@/components/TemperatureCircle";
import { FeedType } from "@/lib/types";
import { LocationTemperatureWidget } from "@/components/LocationTemperatureWidget";
import AdvancedTimeRangeChart from "@/components/AdvancedTimeRangeChart";
import { useSearchParams } from "react-router-dom";

const phReferenceRanges = [
  {
    range: "< 6.0",
    impact: "Too acidic for most plants. Fish stress increases.",
    color: "bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-300"
  },
  {
    range: "6.0 - 6.4",
    impact: "Acceptable for some plants but not ideal. Some fish may be stressed.",
    color: "bg-amber-100 text-amber-800 dark:bg-amber-900/20 dark:text-amber-300"
  },
  {
    range: "6.5 - 7.5",
    impact: "Ideal range for most aquaponic systems. Good for plants and fish.",
    color: "bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-300"
  },
  {
    range: "7.6 - 8.0",
    impact: "Acceptable for most fish but not ideal for many plants.",
    color: "bg-amber-100 text-amber-800 dark:bg-amber-900/20 dark:text-amber-300"
  },
  {
    range: "> 8.0",
    impact: "Too alkaline for most plants. Nutrient availability decreases.",
    color: "bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-300"
  }
];

const tdsReferenceRanges = [
  {
    range: "< 150 ppm",
    impact: "Too low for most plants. Nutrient deficiency likely.",
    color: "bg-amber-100 text-amber-800 dark:bg-amber-900/20 dark:text-amber-300"
  },
  {
    range: "150 - 400 ppm",
    impact: "Ideal range for leafy greens and herbs.",
    color: "bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-300"
  },
  {
    range: "400 - 800 ppm",
    impact: "Ideal range for fruiting plants like tomatoes and peppers.",
    color: "bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-300"
  },
  {
    range: "800 - 1500 ppm",
    impact: "High but acceptable for some plants. Monitor for signs of stress.",
    color: "bg-amber-100 text-amber-800 dark:bg-amber-900/20 dark:text-amber-300"
  },
  {
    range: "> 1500 ppm",
    impact: "Too high for most plants. Risk of toxicity and salt buildup.",
    color: "bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-300"
  }
];

const waterTemperatureRanges = [
  {
    range: "< 65°F (18°C)",
    impact: "Too cold for many warm-water fish and plants. Slow growth.",
    color: "bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-300"
  },
  {
    range: "65-70°F (18-21°C)",
    impact: "Acceptable for cold-water fish and some plants. Growth may be slow.",
    color: "bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-300"
  },
  {
    range: "70-80°F (21-27°C)",
    impact: "Ideal range for most aquaponic systems. Good growth rates.",
    color: "bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-300"
  },
  {
    range: "80-86°F (27-30°C)",
    impact: "Acceptable but high. Monitor oxygen levels closely.",
    color: "bg-amber-100 text-amber-800 dark:bg-amber-900/20 dark:text-amber-300"
  },
  {
    range: "> 86°F (30°C)",
    impact: "Too warm for most systems. Fish stress and low oxygen levels likely.",
    color: "bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-300"
  }
];

export default function WaterQualityPage() {
  const { 
    sensorData, 
    lastUpdated, 
    isLoading,
    error,
    fetchSensorData,
    phData,
    tds1Data,
    useFahrenheit
  } = useAdafruit();
  
  const [searchParams] = useSearchParams();
  const getInitialTab = () => {
    const tab = searchParams.get('tab');
    if (tab === 'ph') return 'ph';
    if (tab === 'tds') return 'tds';
    if (tab === 'temp') return 'temp';
    return 'ph'; // Default to pH
  };
  
  const [activeTab, setActiveTab] = React.useState(getInitialTab());
  
  const hasData = sensorData && (sensorData.ph !== undefined || sensorData.tds1 !== undefined || sensorData.tds2 !== undefined);
  
  const handleExport = () => {
    let dataToExport;
    let fileName;
    let headers;
    
    switch (activeTab) {
      case 'ph':
        dataToExport = phData;
        fileName = 'ph-data';
        headers = ["Timestamp", "pH Value"];
        break;
      case 'tds':
        dataToExport = tds1Data;
        fileName = 'tds-data';
        headers = ["Timestamp", "TDS (ppm)"];
        break;
      case 'temp':
        dataToExport = sensorData.temperatureF !== undefined ? 
          phData : [];
        fileName = 'water-temp-data';
        headers = ["Timestamp", `Temperature (${useFahrenheit ? "°F" : "°C"})`];
        break;
      default:
        dataToExport = [];
        fileName = 'water-quality-data';
        headers = ["Timestamp", "Value"];
    }
    
    if (!dataToExport || dataToExport.length === 0) {
      console.error(`No ${activeTab} data available to export`);
      return;
    }
    
    const csvContent = [
      headers,
      ...dataToExport.map(data => [
        new Date(data.created_at).toLocaleString(),
        data.value
      ])
    ]
      .map(row => row.join(","))
      .join("\n");
    
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${fileName}-${new Date().toISOString().slice(0, 10)}.csv`);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const getPHStatusColor = (phValue: number) => {
    if (phValue < 6.0 || phValue > 9.0) return 'text-red-500';
    if (phValue < 6.5 || phValue > 8.0) return 'text-amber-500';
    return 'text-green-500';
  };
  
  const getPHStatus = (phValue: number) => {
    if (phValue < 5.5) return 'Dangerously Acidic';
    if (phValue < 6.0) return 'Very Acidic';
    if (phValue < 6.5) return 'Acidic';
    if (phValue < 7.5) return 'Neutral';
    if (phValue < 8.0) return 'Slightly Alkaline';
    if (phValue < 9.0) return 'Alkaline';
    if (phValue < 10.0) return 'Very Alkaline';
    return 'Dangerously Alkaline';
  };
  
  const getTDSStatusColor = (tdsValue: number) => {
    if (tdsValue < 50 || tdsValue > 1500) return 'text-red-500';
    if (tdsValue < 150 || tdsValue > 800) return 'text-amber-500';
    return 'text-green-500';
  };
  
  const getTDSStatus = (tdsValue: number) => {
    if (tdsValue < 50) return 'Very Low';
    if (tdsValue < 150) return 'Low';
    if (tdsValue < 300) return 'Ideal for Leafy Greens';
    if (tdsValue < 800) return 'Ideal for Fruiting Plants';
    if (tdsValue < 1500) return 'High';
    return 'Very High';
  };
  
  return (
    <div className="space-y-6 max-w-6xl mx-auto">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
        <div>
          <h1 className="text-3xl font-bold bg-gradient-to-r from-green-600 to-blue-600 bg-clip-text text-transparent">
            Water Quality Dashboard
          </h1>
          <p className="text-slate-500 mt-1">Advanced aquatic analytics for precision hydrochemical monitoring</p>
        </div>
        <div className="flex items-center gap-3">
          <LocationTemperatureWidget />
          <Button 
            variant="outline" 
            className="flex gap-1 items-center" 
            onClick={handleExport}
          >
            <Download className="h-4 w-4" />
            Export
          </Button>
          <RefreshButton onClick={fetchSensorData} isLoading={isLoading} />
        </div>
      </div>
      
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error Loading Water Quality Data</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      
      {!hasData && !isLoading && !error && (
        <Alert variant="default" className="bg-blue-50 border-blue-200 dark:bg-blue-900/20 dark:border-blue-800">
          <Info className="h-5 w-5 text-blue-500" />
          <AlertTitle>No Water Quality Data</AlertTitle>
          <AlertDescription>
            No water quality data is currently available. Please check your sensor connections and try refreshing.
          </AlertDescription>
        </Alert>
      )}
      
      {!isLoading && hasData && (
        <Alert className="bg-green-50 border-green-100 mb-6">
          <Info className="h-4 w-4 text-green-500" />
          <AlertTitle className="text-green-800">Information</AlertTitle>
          <AlertDescription className="text-green-700">
            Water quality readings are collected from multiple sensors monitoring your aquatic system.
          </AlertDescription>
        </Alert>
      )}
      
      <div className="grid grid-cols-1 gap-6">
        <Tabs defaultValue={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="w-full grid grid-cols-3 mb-6">
            <TabsTrigger value="ph" className="flex items-center gap-2">
              <FlaskConical size={16} />
              <span>pH Level</span>
            </TabsTrigger>
            <TabsTrigger value="tds" className="flex items-center gap-2">
              <Waves size={16} />
              <span>TDS (ppm)</span>
            </TabsTrigger>
            <TabsTrigger value="temp" className="flex items-center gap-2">
              <ThermometerSun size={16} />
              <span>Temperature</span>
            </TabsTrigger>
          </TabsList>
          
          <TabsContent value="ph" className="space-y-6">
            {isLoading ? (
              <div className="space-y-4">
                <Skeleton className="h-[300px] w-full" />
              </div>
            ) : sensorData.ph !== undefined ? (
              <>
                <div className="flex justify-center mb-8">
                  <TemperatureCircle 
                    title="pH Level" 
                    value={sensorData.ph}
                    useFahrenheit={false}
                    feedType={FeedType.PH}
                  />
                </div>
                
                <Card className="p-6 rounded-xl border shadow-sm bg-white mb-8">
                  <div className="flex flex-col">
                    <div className="flex justify-between items-start mb-6">
                      <div>
                        <h2 className="text-xl font-bold flex items-center gap-2">
                          <FlaskConical className="h-5 w-5 text-blue-500" />
                          <span>pH Level</span>
                          <span className="text-2xl ml-2 font-bold">
                            {sensorData.ph?.toFixed(1)}
                          </span>
                        </h2>
                        <p className="text-sm text-slate-500 mt-1">
                          <span className={getPHStatusColor(sensorData.ph)}>
                            {getPHStatus(sensorData.ph)}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="h-[400px] w-full">
                      <AdvancedTimeRangeChart activeParameter="ph" />
                    </div>
                  </div>
                </Card>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center h-40 bg-slate-50 dark:bg-slate-800 rounded-xl p-6 border border-dashed border-slate-200 dark:border-slate-700">
                <div className="text-slate-300 dark:text-slate-600 mb-3">
                  <FlaskConical size={48} />
                </div>
                <h3 className="text-lg font-medium text-slate-600 dark:text-slate-300">No pH Data Available</h3>
                <p className="text-sm text-slate-500 mt-1">
                  No data available. Check sensor connections.
                </p>
              </div>
            )}
          </TabsContent>
          
          <TabsContent value="tds" className="space-y-6">
            {isLoading ? (
              <div className="space-y-4">
                <Skeleton className="h-[300px] w-full" />
              </div>
            ) : sensorData.tds1 !== undefined ? (
              <>
                <div className="flex justify-center mb-8">
                  <TemperatureCircle 
                    title="TDS Reading" 
                    value={sensorData.tds1} 
                    useFahrenheit={false}
                    feedType={FeedType.TDS1}
                  />
                </div>
                
                <Card className="p-6 rounded-xl border shadow-sm bg-white mb-8">
                  <div className="flex flex-col">
                    <div className="flex justify-between items-start mb-6">
                      <div>
                        <h2 className="text-xl font-bold flex items-center gap-2">
                          <Waves className="h-5 w-5 text-blue-500" />
                          <span>TDS Level</span>
                          <span className="text-2xl ml-2 font-bold">
                            {Math.round(sensorData.tds1)} ppm
                          </span>
                        </h2>
                        <p className="text-sm text-slate-500 mt-1">
                          <span className={getTDSStatusColor(sensorData.tds1)}>
                            {getTDSStatus(sensorData.tds1)}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="h-[400px] w-full">
                      <AdvancedTimeRangeChart activeParameter="tds" />
                    </div>
                  </div>
                </Card>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center h-40 bg-slate-50 dark:bg-slate-800 rounded-xl p-6 border border-dashed border-slate-200 dark:border-slate-700">
                <div className="text-slate-300 dark:text-slate-600 mb-3">
                  <Waves size={48} />
                </div>
                <h3 className="text-lg font-medium text-slate-600 dark:text-slate-300">No TDS Data Available</h3>
                <p className="text-sm text-slate-500 mt-1 text-center">
                  No data available. Check sensor connections.
                </p>
              </div>
            )}
          </TabsContent>
          
          <TabsContent value="temp" className="space-y-6">
            {isLoading ? (
              <div className="space-y-4">
                <Skeleton className="h-[300px] w-full" />
              </div>
            ) : sensorData.temperatureF !== undefined ? (
              <>
                <div className="flex justify-center mb-8">
                  <TemperatureCircle 
                    title="Water Temperature" 
                    value={sensorData.temperatureF} 
                    useFahrenheit={useFahrenheit}
                    feedType={FeedType.Temperature}
                  />
                </div>
                
                <Card className="p-6 rounded-xl border shadow-sm bg-white mb-8">
                  <div className="flex flex-col">
                    <div className="flex justify-between items-start mb-6">
                      <div>
                        <h2 className="text-xl font-bold flex items-center gap-2">
                          <ThermometerSun className="h-5 w-5 text-blue-500" />
                          <span>Water Temperature</span>
                          <span className="text-2xl ml-2 font-bold">
                            {sensorData.temperatureF?.toFixed(1)} {useFahrenheit ? "°F" : "°C"}
                          </span>
                        </h2>
                        <p className="text-sm text-slate-500 mt-1">
                          {(useFahrenheit && sensorData.temperatureF > 86) || (!useFahrenheit && ((sensorData.temperatureF - 32) * 5/9) > 30) ? (
                            <span className="text-amber-500">Higher than optimal</span>
                          ) : (useFahrenheit && sensorData.temperatureF < 65) || (!useFahrenheit && ((sensorData.temperatureF - 32) * 5/9) < 18) ? (
                            <span className="text-blue-500">Lower than optimal</span>
                          ) : (
                            <span className="text-green-500">Optimal range</span>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="h-[400px] w-full">
                      <AdvancedTimeRangeChart activeParameter="temp" />
                    </div>
                  </div>
                </Card>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center h-40 bg-slate-50 dark:bg-slate-800 rounded-xl p-6 border border-dashed border-slate-200 dark:border-slate-700">
                <div className="text-slate-300 dark:text-slate-600 mb-3">
                  <ThermometerSun size={48} />
                </div>
                <h3 className="text-lg font-medium text-slate-600 dark:text-slate-300">No Temperature Data Available</h3>
                <p className="text-sm text-slate-500 mt-1 text-center">
                  No data available. Check sensor connections.
                </p>
              </div>
            )}
          </TabsContent>
        </Tabs>
      </div>
      
      {lastUpdated && (
        <div className="flex justify-center mt-8">
          <div className="flex items-center gap-2 text-sm text-slate-500">
            <Clock className="h-3.5 w-3.5" />
            <span>Last updated: {formatDate(lastUpdated.toString())}</span>
          </div>
        </div>
      )}
      
      {isLoading && <LoadingOverlay />}
    </div>
  );
}

