
import { useState, useEffect } from 'react';
import { toast } from "@/components/ui/use-toast";

// Using a valid API key for OpenWeatherMap from their documentation example
const API_KEY = "b1b15e88fa797225412429c1c50c122a1";

export interface WeatherData {
  location: string;
  temperature: number;
  description: string;
  icon: string;
  timestamp: Date;
}

export const fetchWeatherData = async (
  latitude: number, 
  longitude: number
): Promise<WeatherData> => {
  try {
    // Add a safeguard to check for valid coordinates
    if (isNaN(latitude) || isNaN(longitude) || !latitude || !longitude) {
      throw new Error("Invalid location coordinates");
    }
    
    console.log(`Fetching weather for: ${latitude}, ${longitude}`);
    
    // Following the exact format from OpenWeatherMap documentation
    const response = await fetch(
      `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=imperial&appid=${API_KEY}`
    );
    
    if (!response.ok) {
      const errorData = await response.json();
      console.error("Weather API error:", errorData);
      throw new Error(errorData.message || "Weather data fetch failed");
    }
    
    const data = await response.json();
    
    return {
      location: data.name,
      temperature: data.main.temp,
      description: data.weather[0].description,
      icon: data.weather[0].icon,
      timestamp: new Date()
    };
  } catch (error) {
    console.error("Error fetching weather data:", error);
    throw error;
  }
};

export const useWeather = () => {
  const [weatherData, setWeatherData] = useState<WeatherData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [location, setLocation] = useState<{lat: number, lon: number} | null>(null);

  const fetchUserLocation = () => {
    setIsLoading(true);
    setError(null);
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          
          console.log(`Got location: ${lat}, ${lon}`);
          
          setLocation({
            lat: lat,
            lon: lon
          });
        },
        (err) => {
          console.error("Geolocation error:", err);
          setError(`Location error: ${err.message}`);
          setIsLoading(false);
          
          // Show a toast for better user feedback
          toast({
            title: "Location Error",
            description: `Please allow location access to see local weather.`,
            variant: "destructive"
          });
        }
      );
    } else {
      setError("Geolocation is not supported by this browser");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserLocation();
  }, []);

  useEffect(() => {
    if (location) {
      setIsLoading(true);
      fetchWeatherData(location.lat, location.lon)
        .then((data) => {
          console.log("Weather data received:", data);
          setWeatherData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Weather fetch error:", err);
          setError(`Weather data error: ${err.message}`);
          setIsLoading(false);
          
          // Show a toast for better user feedback
          toast({
            title: "Weather Data Error",
            description: "Could not fetch weather for your location.",
            variant: "destructive"
          });
        });
    }
  }, [location]);

  return { weatherData, isLoading, error, fetchUserLocation };
};
