
import React from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { FlaskConical, Waves, Thermometer, Info, Droplets } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

const KnowledgePage: React.FC = () => {
  return (
    <div className="space-y-6 max-w-6xl mx-auto">
      {/* Header */}
      <div className="flex flex-col space-y-2">
        <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
          Water Quality Knowledge Base
        </h1>
        <p className="text-slate-500">
          Understanding the key parameters for aquaponics and hydroponics systems
        </p>
      </div>
      
      {/* Introduction Card */}
      <Alert variant="default" className="bg-blue-50 border-blue-200 dark:bg-blue-900/20 dark:border-blue-800">
        <Info className="h-5 w-5 text-blue-500" />
        <AlertTitle>Why Water Quality Matters</AlertTitle>
        <AlertDescription>
          Water quality parameters directly impact plant growth, fish health, and overall system productivity. 
          Understanding these parameters helps you maintain an optimal environment for your aquatic ecosystem.
        </AlertDescription>
      </Alert>
      
      {/* Parameter Tabs */}
      <Tabs defaultValue="ph" className="w-full">
        <TabsList className="grid w-full grid-cols-4">
          <TabsTrigger value="ph" className="flex items-center gap-2">
            <FlaskConical size={16} />
            <span>pH Level</span>
          </TabsTrigger>
          <TabsTrigger value="tds" className="flex items-center gap-2">
            <Waves size={16} />
            <span>TDS (ppm)</span>
          </TabsTrigger>
          <TabsTrigger value="temp" className="flex items-center gap-2">
            <Thermometer size={16} />
            <span>Temperature</span>
          </TabsTrigger>
          <TabsTrigger value="humidity" className="flex items-center gap-2">
            <Droplets size={16} />
            <span>Humidity</span>
          </TabsTrigger>
        </TabsList>
        
        {/* pH Content */}
        <TabsContent value="ph" className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Understanding pH Levels</CardTitle>
              <CardDescription>
                pH measures how acidic or alkaline your water is, on a scale from 0-14
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-2">What is pH?</h3>
                <p className="text-slate-600 dark:text-slate-400">
                  pH is a measure of how acidic or alkaline (basic) a solution is. The scale runs from 0 to 14, with 7 being neutral.
                  Values below 7 indicate acidity, while values above 7 indicate alkalinity. The pH scale is logarithmic, 
                  meaning each step represents a 10-fold change in hydrogen ion concentration.
                </p>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">Optimal pH Ranges</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                  <Card className="border-green-200 dark:border-green-900/30">
                    <CardHeader className="pb-2">
                      <CardTitle className="text-base">For Plants</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="space-y-2 text-sm">
                        <li className="flex justify-between">
                          <span>Most plants:</span>
                          <span className="font-medium">5.5 - 6.5</span>
                        </li>
                        <li className="flex justify-between">
                          <span>Leafy greens:</span>
                          <span className="font-medium">6.0 - 7.0</span>
                        </li>
                        <li className="flex justify-between">
                          <span>Fruiting plants:</span>
                          <span className="font-medium">6.0 - 6.5</span>
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                  
                  <Card className="border-blue-200 dark:border-blue-900/30">
                    <CardHeader className="pb-2">
                      <CardTitle className="text-base">For Fish</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="space-y-2 text-sm">
                        <li className="flex justify-between">
                          <span>Most freshwater fish:</span>
                          <span className="font-medium">6.5 - 7.5</span>
                        </li>
                        <li className="flex justify-between">
                          <span>Tilapia:</span>
                          <span className="font-medium">6.5 - 8.0</span>
                        </li>
                        <li className="flex justify-between">
                          <span>Trout:</span>
                          <span className="font-medium">6.5 - 7.5</span>
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                </div>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">pH Ranges & Their Effects</h3>
                <div className="space-y-2">
                  <div className="p-2 rounded bg-red-100 dark:bg-red-900/20 text-sm">
                    <span className="font-medium">Below 6.0:</span> Too acidic. Can stress fish, reduce beneficial bacteria activity, and limit nutrient availability.
                  </div>
                  <div className="p-2 rounded bg-green-100 dark:bg-green-900/20 text-sm">
                    <span className="font-medium">6.0 - 7.0:</span> Ideal for most aquaponic systems. Good balance for plants, fish, and bacteria.
                  </div>
                  <div className="p-2 rounded bg-green-100 dark:bg-green-900/20 text-sm">
                    <span className="font-medium">7.0:</span> Neutral. Good baseline for most systems.
                  </div>
                  <div className="p-2 rounded bg-amber-100 dark:bg-amber-900/20 text-sm">
                    <span className="font-medium">7.0 - 8.0:</span> Slightly alkaline. Suitable for most fish but may reduce nutrient availability for plants.
                  </div>
                  <div className="p-2 rounded bg-red-100 dark:bg-red-900/20 text-sm">
                    <span className="font-medium">Above 8.0:</span> Too alkaline. May harm fish, reduce fertilizer effectiveness, and cause nutrient lockout.
                  </div>
                </div>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">pH Management Tips</h3>
                <ul className="space-y-2 text-sm list-disc pl-5 text-slate-600 dark:text-slate-400">
                  <li>Monitor pH regularly, ideally at the same time each day</li>
                  <li>Make pH adjustments gradually (no more than 0.2 pH per day)</li>
                  <li>Use food-grade pH adjusters designed for aquaponics</li>
                  <li>For lowering pH: phosphoric acid, citric acid, or vinegar (in small amounts)</li>
                  <li>For raising pH: potassium hydroxide, calcium hydroxide, or baking soda</li>
                  <li>Test pH after adding fish food or supplements</li>
                  <li>pH tends to rise in heavily planted systems due to plant CO₂ consumption</li>
                </ul>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        {/* TDS Content */}
        <TabsContent value="tds" className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Understanding TDS (Total Dissolved Solids)</CardTitle>
              <CardDescription>
                TDS measures the concentration of dissolved substances in your water
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-2">What is TDS?</h3>
                <p className="text-slate-600 dark:text-slate-400">
                  Total Dissolved Solids (TDS) is a measure of all inorganic and organic substances dissolved in water.
                  In aquaponics and hydroponics, TDS primarily indicates the concentration of nutrients available to plants.
                  TDS is typically measured in parts per million (ppm) or milligrams per liter (mg/L).
                </p>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">Optimal TDS Ranges</h3>
                <div className="space-y-2">
                  <div className="p-3 rounded bg-slate-50 dark:bg-slate-800">
                    <h4 className="font-medium mb-1">By Plant Type</h4>
                    <ul className="space-y-2 text-sm">
                      <li className="flex justify-between">
                        <span>Leafy greens (lettuce, spinach):</span>
                        <span className="font-medium">800-1200 ppm</span>
                      </li>
                      <li className="flex justify-between">
                        <span>Herbs (basil, mint):</span>
                        <span className="font-medium">1000-1500 ppm</span>
                      </li>
                      <li className="flex justify-between">
                        <span>Fruiting plants (tomatoes, peppers):</span>
                        <span className="font-medium">1200-1800 ppm</span>
                      </li>
                      <li className="flex justify-between">
                        <span>Heavy feeders (cucumbers, melons):</span>
                        <span className="font-medium">1500-2000 ppm</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">TDS Ranges & Their Effects</h3>
                <div className="space-y-2">
                  <div className="p-2 rounded bg-amber-100 dark:bg-amber-900/20 text-sm">
                    <span className="font-medium">Below 50 ppm:</span> Too low. Insufficient nutrients for most plants.
                  </div>
                  <div className="p-2 rounded bg-green-100 dark:bg-green-900/20 text-sm">
                    <span className="font-medium">50-150 ppm:</span> Low. Suitable for very sensitive plants or seedlings.
                  </div>
                  <div className="p-2 rounded bg-green-100 dark:bg-green-900/20 text-sm">
                    <span className="font-medium">150-300 ppm:</span> Ideal for most leafy greens and herbs.
                  </div>
                  <div className="p-2 rounded bg-green-100 dark:bg-green-900/20 text-sm">
                    <span className="font-medium">300-800 ppm:</span> Good for fruiting crops like tomatoes and peppers.
                  </div>
                  <div className="p-2 rounded bg-amber-100 dark:bg-amber-900/20 text-sm">
                    <span className="font-medium">800-1500 ppm:</span> High. May be stressful for some plants.
                  </div>
                  <div className="p-2 rounded bg-red-100 dark:bg-red-900/20 text-sm">
                    <span className="font-medium">Above 1500 ppm:</span> Too high. Risk of nutrient toxicity and plant damage.
                  </div>
                </div>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">TDS Management Tips</h3>
                <ul className="space-y-2 text-sm list-disc pl-5 text-slate-600 dark:text-slate-400">
                  <li>Different plants need different TDS levels - research your specific crops</li>
                  <li>TDS typically rises over time as water evaporates and nutrients concentrate</li>
                  <li>To lower TDS, perform partial water changes with fresh water</li>
                  <li>To raise TDS, add appropriate nutrients according to system requirements</li>
                  <li>TDS meters and EC (electrical conductivity) meters both measure nutrient concentration</li>
                  <li>1.0 EC ≈ 500-700 ppm depending on the conversion factor used</li>
                  <li>Regular system maintenance helps keep TDS in the optimal range</li>
                </ul>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        {/* Temperature Content */}
        <TabsContent value="temp" className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Understanding Water Temperature</CardTitle>
              <CardDescription>
                Temperature affects dissolved oxygen levels, plant nutrient uptake, and fish metabolism
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-2">Why Temperature Matters</h3>
                <p className="text-slate-600 dark:text-slate-400">
                  Water temperature impacts nearly every aspect of an aquaponic or hydroponic system. It affects:
                </p>
                <ul className="space-y-1 text-sm list-disc pl-5 text-slate-600 dark:text-slate-400 mt-2">
                  <li>Dissolved oxygen levels (warmer water holds less oxygen)</li>
                  <li>Nutrient absorption by plants</li>
                  <li>Fish metabolism and feeding rates</li>
                  <li>Beneficial bacteria activity</li>
                  <li>Growth rates of both plants and fish</li>
                </ul>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">Optimal Temperature Ranges</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                  <Card className="border-green-200 dark:border-green-900/30">
                    <CardHeader className="pb-2">
                      <CardTitle className="text-base">For Plants</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="space-y-2 text-sm">
                        <li className="flex justify-between">
                          <span>Cool-season crops:</span>
                          <span className="font-medium">65-75°F (18-24°C)</span>
                        </li>
                        <li className="flex justify-between">
                          <span>Warm-season crops:</span>
                          <span className="font-medium">70-85°F (21-29°C)</span>
                        </li>
                        <li className="flex justify-between">
                          <span>Optimal for most plants:</span>
                          <span className="font-medium">68-75°F (20-24°C)</span>
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                  
                  <Card className="border-blue-200 dark:border-blue-900/30">
                    <CardHeader className="pb-2">
                      <CardTitle className="text-base">For Fish</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="space-y-2 text-sm">
                        <li className="flex justify-between">
                          <span>Cold-water fish (trout):</span>
                          <span className="font-medium">50-65°F (10-18°C)</span>
                        </li>
                        <li className="flex justify-between">
                          <span>Warm-water fish (tilapia):</span>
                          <span className="font-medium">75-85°F (24-29°C)</span>
                        </li>
                        <li className="flex justify-between">
                          <span>Compromise for aquaponics:</span>
                          <span className="font-medium">70-76°F (21-24°C)</span>
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                </div>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">Temperature Ranges & Their Effects</h3>
                <div className="space-y-2">
                  <div className="p-2 rounded bg-blue-100 dark:bg-blue-900/20 text-sm">
                    <span className="font-medium">Below 65°F (18°C):</span> Too cold for most tropical species. May slow plant growth, but suitable for cold-water fish.
                  </div>
                  <div className="p-2 rounded bg-green-100 dark:bg-green-900/20 text-sm">
                    <span className="font-medium">65-75°F (18-24°C):</span> Ideal for most leafy greens and cool-water fish. Good oxygen levels.
                  </div>
                  <div className="p-2 rounded bg-green-100 dark:bg-green-900/20 text-sm">
                    <span className="font-medium">75-82°F (24-28°C):</span> Optimal for most tropical fish and fruiting plants. Balanced system.
                  </div>
                  <div className="p-2 rounded bg-amber-100 dark:bg-amber-900/20 text-sm">
                    <span className="font-medium">82-86°F (28-30°C):</span> Upper range. Some plants may struggle, but good for certain tropical fish.
                  </div>
                  <div className="p-2 rounded bg-red-100 dark:bg-red-900/20 text-sm">
                    <span className="font-medium">Above 86°F (30°C):</span> Too warm. Plants stress, reduced oxygen in water, increased bacterial growth risk.
                  </div>
                </div>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">Temperature Management Tips</h3>
                <ul className="space-y-2 text-sm list-disc pl-5 text-slate-600 dark:text-slate-400">
                  <li>Stable temperatures are better than fluctuating ones</li>
                  <li>Avoid sudden temperature changes (more than 2°F/1°C per day)</li>
                  <li>Higher temperatures hold less dissolved oxygen - consider additional aeration</li>
                  <li>Insulating tanks can help maintain stable temperatures</li>
                  <li>Shade cloth can reduce sunlight heating in outdoor systems</li>
                  <li>Aquarium heaters can be used for small systems in cold environments</li>
                  <li>Chillers may be necessary in hot climates for cold-water fish</li>
                  <li>Monitor temperature at both the warmest and coolest parts of your system</li>
                </ul>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        {/* Humidity Content */}
        <TabsContent value="humidity" className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Understanding Humidity</CardTitle>
              <CardDescription>
                Humidity affects plant transpiration, nutrient uptake, and disease risk
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-2">What is Humidity?</h3>
                <p className="text-slate-600 dark:text-slate-400">
                  Humidity is the amount of water vapor present in the air. In agriculture and 
                  controlled environments, relative humidity (RH) is most commonly measured, 
                  which is the percentage of water vapor present in the air compared to the 
                  maximum amount the air could hold at that temperature.
                </p>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">Optimal Humidity Ranges</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                  <Card className="border-green-200 dark:border-green-900/30">
                    <CardHeader className="pb-2">
                      <CardTitle className="text-base">For Plants</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="space-y-2 text-sm">
                        <li className="flex justify-between">
                          <span>Seedlings/Cuttings:</span>
                          <span className="font-medium">70-90%</span>
                        </li>
                        <li className="flex justify-between">
                          <span>Vegetative Growth:</span>
                          <span className="font-medium">60-70%</span>
                        </li>
                        <li className="flex justify-between">
                          <span>Flowering/Fruiting:</span>
                          <span className="font-medium">40-60%</span>
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                  
                  <Card className="border-blue-200 dark:border-blue-900/30">
                    <CardHeader className="pb-2">
                      <CardTitle className="text-base">By Crop Type</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="space-y-2 text-sm">
                        <li className="flex justify-between">
                          <span>Leafy Greens:</span>
                          <span className="font-medium">50-70%</span>
                        </li>
                        <li className="flex justify-between">
                          <span>Fruiting Vegetables:</span>
                          <span className="font-medium">65-75%</span>
                        </li>
                        <li className="flex justify-between">
                          <span>Herbs:</span>
                          <span className="font-medium">45-60%</span>
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                </div>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">Humidity Ranges & Their Effects</h3>
                <div className="space-y-2">
                  <div className="p-2 rounded bg-red-100 dark:bg-red-900/20 text-sm">
                    <span className="font-medium">Below 30%:</span> Too dry. Plants lose water rapidly, nutrients can't be transported efficiently, and leaves may curl or wilt.
                  </div>
                  <div className="p-2 rounded bg-amber-100 dark:bg-amber-900/20 text-sm">
                    <span className="font-medium">30-40%:</span> Low. May stress some plants, but suitable for arid-adapted species. Increased transpiration and watering needs.
                  </div>
                  <div className="p-2 rounded bg-green-100 dark:bg-green-900/20 text-sm">
                    <span className="font-medium">40-60%:</span> Ideal for most mature plants and flowering/fruiting stages. Good balance for transpiration and nutrients.
                  </div>
                  <div className="p-2 rounded bg-green-100 dark:bg-green-900/20 text-sm">
                    <span className="font-medium">60-70%:</span> Great for vegetative growth. Many leafy plants thrive in this range.
                  </div>
                  <div className="p-2 rounded bg-amber-100 dark:bg-amber-900/20 text-sm">
                    <span className="font-medium">70-80%:</span> High. Good for propagation but can increase disease risk if maintained too long with mature plants.
                  </div>
                  <div className="p-2 rounded bg-red-100 dark:bg-red-900/20 text-sm">
                    <span className="font-medium">Above 80%:</span> Too humid. Increased risk of mold, mildew, fungal diseases, and pest problems. Reduced transpiration.
                  </div>
                </div>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">Humidity Management Tips</h3>
                <ul className="space-y-2 text-sm list-disc pl-5 text-slate-600 dark:text-slate-400">
                  <li>Monitor humidity at plant level, not just ambient room air</li>
                  <li>Increase humidity by using misters, humidity trays, or small humidifiers</li>
                  <li>Decrease humidity with dehumidifiers, increased ventilation, or air circulation</li>
                  <li>Higher temperatures hold more moisture, so temperature and humidity are linked</li>
                  <li>Consider vapor pressure deficit (VPD), which factors in both temperature and humidity</li>
                  <li>Optimal humidity often changes throughout the plant growth cycle</li>
                  <li>Humidity is especially critical during propagation when young plants are establishing roots</li>
                  <li>Too high humidity can cause edema (water blisters) on leaves</li>
                  <li>Lower humidity during flowering to reduce mold risk on dense flower clusters</li>
                </ul>
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-lg font-medium mb-2">The Impact of Humidity on Plant Health</h3>
                <div className="space-y-4">
                  <div>
                    <h4 className="font-medium">Transpiration & Nutrient Transport</h4>
                    <p className="text-sm text-slate-600 dark:text-slate-400">
                      Humidity directly affects transpiration - the process by which plants release water vapor. 
                      This process creates the necessary pressure gradient for nutrient uptake and transport. 
                      In conditions that are too humid, transpiration slows down, potentially limiting nutrient 
                      availability. Conversely, when humidity is too low, plants lose water faster than they can absorb it.
                    </p>
                  </div>
                  
                  <div>
                    <h4 className="font-medium">Disease Prevention</h4>
                    <p className="text-sm text-slate-600 dark:text-slate-400">
                      High humidity creates favorable conditions for fungal pathogens and bacterial diseases. 
                      Many common plant diseases like powdery mildew, botrytis, and leaf spot thrive in humid environments. 
                      Maintaining proper airflow and monitoring humidity levels is crucial for disease prevention.
                    </p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default KnowledgePage;
