
import { useLocation, Link } from "react-router-dom";
import { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { AlertTriangle } from "lucide-react";

const NotFound = () => {
  const location = useLocation();

  useEffect(() => {
    console.error(
      "404 Error: User attempted to access non-existent route:",
      location.pathname
    );
    
    // For debugging - log how this route was accessed
    console.log("Current URL:", window.location.href);
    console.log("Current Path:", window.location.pathname);
    console.log("Current Hash:", window.location.hash);
  }, [location.pathname]);

  return (
    <div className="min-h-[80vh] flex flex-col items-center justify-center">
      <div className="text-center max-w-md mx-auto p-6 bg-white dark:bg-slate-800 rounded-lg shadow-md">
        <AlertTriangle className="w-16 h-16 mx-auto mb-4 text-amber-500" />
        <h1 className="text-4xl font-bold mb-4">404</h1>
        <p className="text-xl text-gray-600 dark:text-gray-400 mb-6">
          Oops! The page you're looking for doesn't exist.
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 mb-6">
          Path: {location.pathname}
        </p>
        <Button asChild className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700">
          <Link to="/">
            Return to Dashboard
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
