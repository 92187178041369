
export interface AdafruitFeedData {
  id: string;
  value: string;
  created_at: string;
}

export interface SensorData {
  humidity?: number;
  temperatureF?: number;
  thermocoupleF?: number;
  distance?: number;
  tds1?: number;
  tds2?: number;
  ph?: number;
}

export enum FeedType {
  Distance = "Distance",
  Humidity = "Humidity",
  Temperature = "Temperature",
  Thermocouple = "Thermocouple",
  TDS1 = "TDS1",
  TDS2 = "TDS2",
  PH = "PH"
}

export interface FeedTypeConfig {
  icon: string;
  color: string;
  gradientStart: string;
  gradientEnd: string;
}

export const feedTypeConfigs: Record<FeedType, FeedTypeConfig> = {
  [FeedType.Distance]: {
    icon: "ruler",
    color: "#9333ea", // purple
    gradientStart: "#9333ea", // purple
    gradientEnd: "#ec4899" // pink
  },
  [FeedType.Humidity]: {
    icon: "droplets",
    color: "#3b82f6", // blue
    gradientStart: "#3b82f6", // blue
    gradientEnd: "#06b6d4" // cyan
  },
  [FeedType.Temperature]: {
    icon: "thermometer",
    color: "#f97316", // orange
    gradientStart: "#ef4444", // red
    gradientEnd: "#f97316" // orange
  },
  [FeedType.Thermocouple]: {
    icon: "flame",
    color: "#dc2626", // red
    gradientStart: "#f97316", // orange
    gradientEnd: "#dc2626" // red
  },
  [FeedType.TDS1]: {
    icon: "waves",
    color: "#0ea5e9", // sky blue
    gradientStart: "#0ea5e9", // sky blue
    gradientEnd: "#2563eb" // blue
  },
  [FeedType.TDS2]: {
    icon: "waves",
    color: "#6366f1", // indigo
    gradientStart: "#6366f1", // indigo
    gradientEnd: "#4f46e5" // indigo
  },
  [FeedType.PH]: {
    icon: "flask-conical",
    color: "#10b981", // emerald
    gradientStart: "#10b981", // emerald
    gradientEnd: "#059669" // green
  }
};
