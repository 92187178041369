import { useAdafruit } from "@/lib/AdafruitContext";
import { LoadingOverlay } from "@/components/LoadingOverlay";
import { formatDate } from "@/lib/utils/date-utils";
import { UnitToggle } from "@/components/UnitToggle";
import { Clock, Download } from "lucide-react";
import { Button } from "@/components/ui/button";
import { RefreshButton } from "@/components/RefreshButton";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { LocationTemperatureWidget } from "@/components/LocationTemperatureWidget";
import TemperatureTimeRangeChart from "@/components/TemperatureTimeRangeChart";
import { useToast } from "@/hooks/use-toast";
import { FeedType } from "@/lib/types";
import { useSearchParams } from "react-router-dom";

export default function TemperaturePage() {
  const { 
    sensorData, 
    lastUpdated, 
    isLoading,
    useFahrenheit,
    setUseFahrenheit,
    fetchSensorData,
    temperatureData,
    thermocoupleData
  } = useAdafruit();
  
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get('tab') === 'thermocouple' ? 'thermocouple' : 'temp';

  const hasError = !sensorData || (!sensorData.temperatureF && !sensorData.thermocoupleF);
  
  const handleExport = () => {
    // Determine which data to export (both temperature and thermocouple)
    const tempData = temperatureData.map(item => ({
      parameter: 'Air Temperature',
      value: item.value,
      unit: useFahrenheit ? '°F' : '°C',
      timestamp: formatDate(item.created_at)
    }));
    
    const thermoData = thermocoupleData.map(item => ({
      parameter: 'Process Temperature',
      value: item.value,
      unit: useFahrenheit ? '°F' : '°C',
      timestamp: formatDate(item.created_at)
    }));
    
    // Combine the data
    const combinedData = [...tempData, ...thermoData];
    
    // Convert to CSV
    const headers = ['Parameter', 'Value', 'Unit', 'Timestamp'];
    const csvContent = [
      headers.join(','),
      ...combinedData.map(row => 
        [
          row.parameter,
          row.value,
          row.unit,
          row.timestamp
        ].join(',')
      )
    ].join('\n');
    
    // Create and download the file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `temperature-data-${new Date().toISOString().split('T')[0]}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    toast({
      title: "Export Successful",
      description: "Temperature data has been exported to CSV",
    });
  };

  return (
    <div className="space-y-6 max-w-6xl mx-auto">
      {/* Header */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
        <div>
          <h1 className="text-3xl font-bold bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Temperature Dashboard
          </h1>
          <p className="text-slate-500 mt-1">Precision thermal analytics for optimal climate control</p>
        </div>
        <div className="flex items-center gap-3">
          <LocationTemperatureWidget />
          
          <Button 
            variant="outline" 
            className="flex gap-2 items-center"
            onClick={handleExport}
          >
            <Download size={16} />
            Export
          </Button>
          <RefreshButton onClick={fetchSensorData} isLoading={isLoading} />
        </div>
      </div>
      
      {hasError && (
        <Alert variant="destructive">
          <AlertTitle>Error Loading Temperature Data</AlertTitle>
          <AlertDescription>
            Unable to load temperature data. Please check your connection and try refreshing.
          </AlertDescription>
        </Alert>
      )}
      
      {!hasError && (
        <>
          <div className="mb-6 flex justify-end">
            <UnitToggle 
              value={useFahrenheit} 
              onChange={setUseFahrenheit} 
              labels={["°F", "°C"]} 
            />
          </div>
          
          <TemperatureTimeRangeChart initialTab={initialTab} />
          
          {lastUpdated && (
            <div className="flex items-center justify-center gap-2 text-sm text-slate-500 mt-4 p-2 bg-white dark:bg-slate-800 rounded-full shadow inline-block mx-auto">
              <Clock className="h-3.5 w-3.5" />
              <span>Last updated: {lastUpdated ? formatDate(lastUpdated.toString()) : ''}</span>
            </div>
          )}
        </>
      )}
      
      {isLoading && <LoadingOverlay />}
    </div>
  );
}
